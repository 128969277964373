import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@pankod/refine-mui'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react'


export default function EditProductModal({ dialogState, yesFunction, product }) {

  const [productId, setProductId] = React.useState('');
  const [productName, setProductName] = React.useState('');
  const [productCode, setProductCode] = React.useState('');
  const [quantity, setQuantity] = React.useState(0);
  const [firstPrice, setFirstPrice] = React.useState(0);
  const [deduction, setDeduction] = React.useState(0);
  const [lastPrice, setLastPrice] = React.useState(0);
  const [lastPriceWithTax, setLastPriceWithTax] = React.useState(0);
  

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setProductId(product._id);

    if (!product.product.productName) setProductName('');
    else setProductName(product.product.productName);

    if (!product.product.productCode) setProductCode('');
    else setProductCode(product.product.productCode);

    if (!product.product.quantity) setQuantity(0);
    else setQuantity(product.product.quantity);

    if (!product.product.firstPrice) setFirstPrice(0);
    else setFirstPrice(product.product.firstPrice);

    if (!product.product.deduction) setDeduction(0);
    else setDeduction(product.product.deduction);

    if (!product.product.lastPrice) setLastPrice(0);
    else setLastPrice(product.product.lastPrice);

    setLastPriceWithTax(Number(product.product.lastPrice) * 1.17);
  }, [dialogState, product]);

  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleProductCodeChange = (event) => {
    setProductCode(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleFirstPriceChange = (event) => {
    const newFirstPrice = parseFloat(event.target.value);
    setFirstPrice(newFirstPrice);
    setLastPrice(newFirstPrice * (1 - (deduction/100)));
    setLastPriceWithTax((newFirstPrice * (1 - (deduction/100))) * 1.17);
  };

  const handleDeductionChange = (event) => {
    const newDeduction = parseFloat(event.target.value);
    setDeduction(newDeduction);
    setLastPrice(firstPrice * (1 - (newDeduction/100)));
    setLastPriceWithTax((firstPrice * (1 - (newDeduction/100))) * 1.17);
  };

  const handleLastPriceChange = (event) => {
    const newLastPrice = parseFloat(event.target.value);
    setLastPrice(newLastPrice);
    setLastPriceWithTax(newLastPrice * 1.17);
    if (newLastPrice > firstPrice) {
      setFirstPrice(newLastPrice);
      setDeduction(0);
    } else {
      const diff = firstPrice - newLastPrice;
      if (firstPrice !== 0) {
        setDeduction((diff / firstPrice) * 100);
      } else {
        setFirstPrice(newLastPrice);
        setDeduction(0);
      }
    }
  };

  
  const handleLastPriceWithTaxChange = (event) => {
    const newLastPriceWithTax = parseFloat(event.target.value);
    setLastPriceWithTax(newLastPriceWithTax);
    const newLastPrice = newLastPriceWithTax / 1.17;
    setLastPrice(newLastPrice);
    if (newLastPrice > firstPrice) {
      setFirstPrice(newLastPrice);
      setDeduction(0);
    } else {
      const diff = firstPrice - newLastPrice;
      if (firstPrice !== 0) {
        setDeduction((diff / firstPrice) * 100);
      } else {
        setFirstPrice(newLastPrice);
        setDeduction(0);
      }
    }
  };
  
  return (
    <Dialog
        open={dialogState}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          עריכת פרטי מוצר 
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={5}>
          <TextField
              label="מוצר"
              value={productName}
              onChange={handleProductNameChange}
              sx={{ width: '100%', direction: 'rtl' }}
            />
            <TextField
              label="מספר פריט"
              value={productCode}
              onChange={handleProductCodeChange}
              sx={{ width: '100%' }}
            />
            <TextField
              label="כמות"
              name="quantity"
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              inputProps={{ min: 0 }}
            />
            <TextField
              label="מחיר"
              name="firstPrice"
              type="number"
              value={firstPrice}
              onChange={handleFirstPriceChange}
              inputProps={{ min: 0, step: 0.0000000000000000000001 }}

            />
            <TextField
              label="הנחה"
              name="deduction"
              type="number"
              value={deduction}
              onChange={handleDeductionChange}
              inputProps={{ min: 0, max: 100, step: 0.0000000000000000000001 }}
            />
            <TextField
              label="מחיר אחרי הנחה"
              name="lastPrice"
              type="number"
              value={lastPrice}
              onChange={handleLastPriceChange}
              inputProps={{ min: 0, step: 0.0000000000000000000001 }}
            />
            <TextField
              label="מחיר כולל מע'ם"
              name="lastPriceWithTax"
              type="number"
              value={lastPriceWithTax}
              onChange={handleLastPriceWithTaxChange}
              inputProps={{ min: 0, step: 0.0000000000000000000001 }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ direction: 'rtl' }}>
          <Button sx={{ backgroundColor: 'red', color: 'white', borderRadius: '30px', ml: 1 }} onClick={() => dialogState(false)}>ביטול</Button>
          <Button sx={{ backgroundColor: 'green', color: 'white', borderRadius: '30px', mr: 1 }} autoFocus onClick={() => {
              yesFunction({ productId, productName, productCode, quantity, firstPrice, deduction, lastPrice, lastPriceWithTax, });
          }}>עריכה</Button>
        </DialogActions>
    </Dialog>
  )  
}