import React, { useContext, } from "react";
import { useRouterContext, TitleProps } from "@pankod/refine-core";
import { Button } from "@pankod/refine-mui";
import { ColorModeContext } from "contexts";
import bigLogoDark from '../../../assets/bigLogoDark.png'
import bigLogoWhite from '../../../assets/bigLogoWhite.png'
import smallLogoDark from '../../../assets/smallLogoDark.png'
import smallLogoWhite from '../../../assets/smallLogoWhite.png'

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { mode } = useContext(ColorModeContext);
  const isLightMode = mode === 'light';

  const { Link } = useRouterContext();

  return (
    <Button fullWidth variant="text" disableRipple>
      <Link to="/">
        {collapsed ? (
          <img src={isLightMode ? smallLogoWhite : smallLogoDark} alt="logoSmall" width="45px" />
        ) : (
          <img src={isLightMode ? bigLogoWhite : bigLogoDark} alt="logoBig" width="160px" />
        )}
      </Link>
    </Button>
  );
};
