import React, { useContext, } from "react";
import { Box, Button, FormControl, FormHelperText, TextField, Typography } from '@pankod/refine-mui'
import { useForm } from "@pankod/refine-react-hook-form";
import { useNotification } from '@pankod/refine-core';
import { ColorModeContext } from "contexts";

const CreateSupplier = () => {
  const { mode } = useContext(ColorModeContext);
  const { open } = useNotification();

  const textColor = mode === 'light' ? 'black' : 'white';

  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
        if (data?.response) {
          let responseMessage = data?.response?.data?.message
          open?.({
            type: "error",
            message: responseMessage,
            undoableTimeout: 6,
            description: 'טעות בנתונים',
          });
        }
      },
    },
  });

  return (
    <Box
    borderRadius="10px"
    padding="15px">
      <form onSubmit={handleSubmit(onFinish)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>
        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
             <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor, }}>שם ספק</Typography>
          </FormHelperText>
          <TextField
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            {...register("companyName", { required: false })} 
            sx={{fontSize:20, color: textColor}} 
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '18px' } }} 
          />
        </FormControl><br/><br/>
        
        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor, }}>מספר חברה</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            {...register("companyNumber", { required: false })} 
            sx={{fontSize:20, color: textColor}} 
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '18px' } }}
          />
        </FormControl><br/><br/>
        
        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor, }}>מספר טל</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            {...register("phoneNumber", { required: false })} 
            sx={{fontSize:20, color: textColor}} 
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '18px' } }}
          />
        </FormControl><br/><br/>
        <Button type="submit" variant="contained" color="success" disabled={formLoading}>הוספה</Button>
            {formLoading && <p>...טוען</p>}
      </form>
    </Box>
  )
}

export default CreateSupplier