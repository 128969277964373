import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, FormControl, FormHelperText, MenuItem, Select, Stack, TextField, TextareaAutosize, Typography } from '@pankod/refine-mui';
import { FieldValues, useForm } from "@pankod/refine-react-hook-form";
import { ColorModeContext } from "contexts";
import { useNotification } from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import { url } from 'assets/url';
import axios from 'axios';

function CreateCheck() {
  let { id } = useParams();
  const { open } = useNotification();

  useEffect(() => {
    axios.get(`${url}/Checks/${id}`, { withCredentials: true })
    .then((response : any) => {
      // Handle successful response
      console.log('Data:', response.data);
      // Assuming response.data is an object containing the desired data
      const data = response.data;
      setDay(data?.date?.day);
      setMonth(data?.date?.month);
      setYear(data?.date?.year);
      setTakeDay(data?.takeDate?.day);
      setTakeMonth(data?.takeDate?.month);
      setTakeYear(data?.takeDate?.year);
      setReceive(data?.receive ? 1 : 0); 
    })
    .catch((error : any) => {
      // Handle error
      console.error('Error fetching data:', error);
    });
}, [id]);
  
  const [day, setDay] = useState<any>(1);
  const [month, setMonth] = useState<any>((new Date()).getMonth() + 1);
  const [year, setYear] = useState<any>((new Date()).getFullYear());

  const [takeDay, setTakeDay] = useState<any>(1);
  const [takeMonth, setTakeMonth] = useState<any>((new Date()).getMonth() + 1);
  const [takeYear, setTakeYear] = useState<any>((new Date()).getFullYear());

  const [receive, setReceive] = useState<number | string>(1);

  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';


  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
          if (data?.response) {
            let responseMessage = data?.response?.data?.message
            open?.({
              type: "error",
              message: responseMessage,
              undoableTimeout: 6,
              description: 'טעות בנתונים',
            });
          }
        },
    },
  });

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({
      ...data,
      receive,
      date: {day, month, year},
      takeDate: { day: takeDay, month: takeMonth, year: takeYear },
    });
  };

  return (
    <Box
        borderRadius="10px"
        padding="15px">
      <form onSubmit={handleSubmit(onFinishHandler)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>

        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
          תיאור 
        </FormHelperText>
        <FormControl fullWidth>
          <TextareaAutosize
            style={{
              width: "100%",
              background: "transparent",
              fontSize: "17px",
              borderColor: textColor,
              borderRadius: 6,
              padding: 10,
              color: textColor,
              direction: 'rtl', 
              resize: 'none',
            }}
            minRows={2}
            color="primary"
            id="outlined-basic"
            {...register("description")}
          />
        </FormControl><br/>
      
        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>שם בנק</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '17px' } }} 
            {...register("bank", { required: true })} 
          />
        </FormControl><br/>

        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>מספר סניף</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '17px' } }} 
            {...register("branch", { required: true })}
          />
        </FormControl><br/>

        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>מספר חשבון</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '17px' } }} 
            {...register("account", { required: true })}
          />
        </FormControl><br/>

        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>מספר צ"יק</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '17px' } }} 
            {...register("number", { required: true })}
          />
        </FormControl><br/>

        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>סכום</Typography>
          </FormHelperText>
          <TextField
            id="outlined-basic"
            color="info"
            variant="outlined"
            type="number"
            inputProps={{ 
              min: 0,
              step: 0.0000000000000000000000001,
              pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
              style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '17px' }
            }}
            {...register("price", { required: true })} sx={{fontSize:20, color: '#000000'}} 
          />
        </FormControl><br/>

        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>טל + שם איש קשר</Typography>
          </FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { direction: 'rtl', textAlign: 'right', color: textColor, fontSize: '17px' } }} 
            {...register("person", { required: false })}
          />
        </FormControl><br/>

        <Stack direction={{ xs: 'column-reverse', md: 'row' }}
              justifyContent="flex-end"
              alignItems={{ xs: 'flex-end', md: 'flex-start' }}
              spacing={2}>
          <Stack direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}>
              <TextField id="outlined-basic" label="יום" color="info" variant="outlined" value={day} onChange={(e) => setDay(Number(e.target.value))} sx={{direction: 'rtl', }}/>
              <TextField id="outlined-basic" label="חודש" color="info" variant="outlined" value={month} onChange={(e) => setMonth(Number(e.target.value))} sx={{direction: 'rtl', }}/>
              <TextField id="outlined-basic" label="שנה" color="info" variant="outlined" value={year} onChange={(e) => setYear(Number(e.target.value))} sx={{direction: 'rtl', }}/>
          </Stack>
          <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, direction: 'rtl', textAlign: 'right', }}>תאריך פרעון הצי"ק</Typography>
        </Stack><br/>

        <Stack direction={{ xs: 'column-reverse', md: 'row' }}
          justifyContent="flex-end"
          alignItems={{ xs: 'flex-end', md: 'flex-start' }}
          spacing={2}>
          <Stack direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}>
            <TextField id="outlined-takeDay" label="יום" color="info" variant="outlined" value={takeDay} onChange={(e) => setTakeDay(Number(e.target.value))} sx={{direction: 'rtl', }}/>
            <TextField id="outlined-takeMonth" label="חודש" color="info" variant="outlined" value={takeMonth} onChange={(e) => setTakeMonth(Number(e.target.value))} sx={{direction: 'rtl', }}/>
            <TextField id="outlined-takeYear" label="שנה" color="info" variant="outlined" value={takeYear} onChange={(e) => setTakeYear(Number(e.target.value))} sx={{direction: 'rtl', }}/>
          </Stack>
          <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>תאריך כתיבת הצי"ק</Typography>
        </Stack><br/>


        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right', direction: 'rtl'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor, }}>האם אתה מקבל או משלם הצי"ק ?</Typography>
          </FormHelperText>
            <Select
              value={receive}
              id="component-simple"
              onChange={(e:any) => setReceive(e.target.value)}
              sx={{ fontSize: 21, fontWeight: 'bold', direction: 'rtl', textAlign: 'right', color: textColor, }}
            >
              <MenuItem value={1} sx={{ direction: 'rtl', textAlign: 'right', color: textColor, fontWeight: 'bold', fontSize: '18px'}}>מקבל</MenuItem>
              <MenuItem value={0} sx={{ direction: 'rtl', textAlign: 'right', color: textColor, fontWeight: 'bold', fontSize: '18px'}}>משלם</MenuItem>
            </Select>
        </FormControl><br/><br/>

        <Button type="submit" variant="contained" color="success" disabled={formLoading}>עריכה</Button>
            {formLoading && <p>...טוען</p>}
      </form>
    </Box>
  )
}

export default CreateCheck;
