import { useTable } from '@pankod/refine-core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@pankod/refine-mui'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';


export default function AddProductDialog({dialogState, yesFunction}) {
  const { tableQueryResult: productsTableQueryResult }
    = useTable({ resource: "Products" })
  const products = productsTableQueryResult.data?.data ?? [];
  
  const [quantity, setQuantity] = React.useState(0);
  const [product, setProduct] = React.useState();
  const [dedication, setDedication] = React.useState(0);
  const [priceAfterDedication, setPriceAfterDedication] = React.useState(0);

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity.target.value);
  };

  const handleDedicationChange = (dedication) => {
    setPriceAfterDedication(product.sellPrice * (1-(Number(dedication.target.value)/100)))
    setDedication(dedication.target.value);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Dialog
        sx={{ direction: 'rtl' }}
        open={dialogState}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
          הוספת מוצר לקניה
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={4}>
            <Autocomplete
              required
              disablePortal
              id="combo-box-demo"
              options={products}
              getOptionLabel={(option) => option.name + " " + option.productCode}
              onChange={(event, newProduct) => {
                setProduct(newProduct)
                setPriceAfterDedication(newProduct.sellPrice)
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="מוצר"  style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}/>}
            />

            <TextField
              required
              value={dedication}
              inputProps={{ min: 0, max: 100, step: 0.000001}}
              label="הנחה ליחידה"
              variant="outlined"
              type="number"
              id="dedication"
              name="dedication"
              onChange={handleDedicationChange}
            />
            {(product && 
            <Stack 
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
              <Typography fontSize="19px" fontWeight="bold">מחיר יחידה: {product.sellPrice}</Typography>
              <TextField
                value={priceAfterDedication}
                inputProps={{ step: 0.000000001 }}
                label="מ. אחרי הנחה"
                variant="outlined"
                type="number"
                id="priceAfterDeduction"
                name="priceAfterDeduction"
                onChange={(event) => {
                  if (event.target.value <= product.sellPrice) {
                    setPriceAfterDedication(event.target.value)
                    setDedication( 100 * ((product.sellPrice - event.target.value)/product.sellPrice))
                  }
                }}
              />
            </Stack>)
              || ""}
            <br/>
            {(product && 
            <Typography fontSize="19px" fontWeight="bold">יש במחסן: {product.quantity}</Typography>) || ""}
            <TextField
              required
              inputProps={{ min: 1}}
              label="כמות"
              variant="outlined"
              type="number"
              id="message"
              name="message"
              onChange={handleQuantityChange}
            />
            {(product && <Typography fontSize="19px" fontWeight="bold">מחיר. סופי {product.sellPrice * (1 - (dedication/100)) * quantity}</Typography>)}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button sx={{ backgroundColor: 'red', color: 'white', borderRadius: '30px', ml: 1 }} onClick={() => dialogState(false)}>ביטול</Button>
          <Button sx={{ backgroundColor: 'green', color: 'white', borderRadius: '30px', mr: 1 }} autoFocus onClick={() => {
            if (Object.keys(product).length) {
              dialogState(false)
              yesFunction(product, quantity, dedication);
            }
          }}>להוסיף</Button>
        </DialogActions>
    </Dialog>
  )  
}

