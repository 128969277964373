import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Delete } from '@mui/icons-material';

const eventDialog = ({ time, eventDef, deleteFunction, modalStatus }) => {

  const startTimeString = time.start.toLocaleString('he-IL', { timeZone: 'UTC' });
  const endTimeString = time.end.toLocaleString('he-IL', { timeZone: 'UTC' });

  return (
    <Dialog
      open={modalStatus}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{ eventDef.title }</DialogTitle>
      <DialogContent >
        <DialogContentText><strong>מ: {startTimeString}</strong></DialogContentText>
        <DialogContentText><strong>ל: {endTimeString}</strong></DialogContentText>
        <DialogContentText><strong>תיאור: {eventDef.extendedProps.description}</strong></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modalStatus(false)}>close</Button>
        <Button onClick={() => {
          modalStatus(false)
          deleteFunction(eventDef.extendedProps._id);
        }}><Delete sx={{color: '#FF3535'}}/></Button>
      </DialogActions>
    </Dialog>
  )
}

export default eventDialog