import { Add, AirlineStops, Payment, Person, Search, Visibility, Delete } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'
import { useLogout, useDelete } from '@pankod/refine-core'
import { Button, CircularProgress, Typography, CardContent, CardActions, Card, TextField, InputAdornment, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Chip, Tooltip, IconButton} from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { useState, useContext, useEffect, ChangeEvent, useCallback } from 'react'
import { ColorModeContext } from "contexts";
import ConfirmationDialog from 'components/ConfirmationDialog'
import axios from 'axios'
import { url } from 'assets/url'

const OrdersHistory = () => {
  const { mode } = useContext(ColorModeContext);
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === 'light' ? 'black' : 'white';

  const { mutate } = useDelete();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { mutate: mutateLogout } = useLogout();

  const [orderToDelete, setOrderToDelete] = useState(false);

  /*
  const {
    tableQueryResult: {data, isLoading, isError, isFetching, isRefetching }
  } = useTable();
  
  let orders = data?.data ?? [];
  console.log(data)

  if(isLoading || isFetching || isRefetching) return <center><CircularProgress disableShrink /></center>
  
  if(isError) { 
      mutateLogout() 
      navigate('/login') 
  }
  */
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);

  const fetchOrders = async (searchTerm = '', pageNum = 1) => {
    setLoading(true);
    try {
        const response = await axios.get(`${url}/Orders_History/getAllOrdersWithPagination` , {
          params: { search: searchTerm, page: pageNum, limit: 20 },
          withCredentials: true,
        });
        const { orders: fetchedOrders, totalOrders } = response.data;
        setOrders((prevOrders) => pageNum === 1 ? fetchedOrders : [...prevOrders, ...fetchedOrders]);
        setHasMore(fetchedOrders.length > 0 && orders.length < totalOrders);
    } catch (error:any) {
        console.error(error);
        if (error?.response && error?.response.status === 401) {
          mutateLogout() 
          navigate('/login') 
        }
    } finally {
      setLoading(false);
    }
  };
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setPage(1);
    fetchOrders(searchTerm, 1);
  };
  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 && !loading && hasMore) {
      console.log('Fetching more products...');
      setPage((prevPage) => prevPage + 1);
      setScrollLoading(true);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, loading, hasMore]);
  useEffect(() => {
    fetchOrders(search, page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page, search]);
  useEffect(() => {
    setScrollLoading(false);
  }, [orders]);

  const handleClickOrderRemoved = (id: String) => {
    mutate(
      {
        resource: "Orders_History",
        id: id as string,
      },
      {
        onSuccess: () => {
          navigate("/Orders_History");
        },
      },
    );
  }; 

  return (
    <Box style={{ overflowX: "hidden" }}>
      <Stack direction='row-reverse'
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={12}>
        <Typography fontSize='25px'
          fontWeight='bold'
          color={textColor}
        >
            הזמנות
        </Typography>
        <Button variant="contained" endIcon={<Add/>} sx={{boxShadow: 3, borderRadius: 5}}
         onClick={() => navigate('/Orders_History/create')}>הוספת הזמנה</Button>
      </Stack><br/>

      <TextField
        fullWidth
        id="input-with-icon-textfield"
        label="חיפוש"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={handleSearchChange}
        value={search}
        inputProps={{ style: { direction: 'rtl', } }}
      /><br/><br/>

      <Box sx={{ display: { xs: 'none', md: 'block' }}}>
        <TableContainer sx={{ direction: 'rtl', }}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow sx={{ bgcolor: tableHead }}>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor }}>מספר הזמנה</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor }}>לקוח</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor }}>זמן ההזמנה</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor }}>סטטוס</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor }}>זיכוי</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor }}>פעולות</TableCell>
              </TableRow>
            </TableHead>
            {loading && page === 1 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
                <CircularProgress
                  disableShrink
                  size={60}
                  thickness={5}
                  style={{ marginTop: '20px' }}
                />
              </Box>
            ) : (
            <TableBody>
              {orders.length === 0 && <h3>אין הזמנות</h3>}
              {orders.map((order) => (
                  <TableRow key={order._id}>
                    <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{order.invoicingNum.toString().padStart(6, '0')}</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{order.customerName}</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{new Date(order.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</TableCell>
                    <TableCell sx={{ textAlign: 'center', color: textColor }}>
                      {order.ifPayed ? (
                        <Chip label="שולם" sx={{ fontSize: '19px', bgcolor: '#99FA60', color: textColor  }} />
                      ) : (
                        <Chip label="לא שולם" sx={{ fontSize: '19px', bgcolor: '#FA8A79', borderRadius: '10px', color: textColor }} />
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', color: textColor }}>
                      <Chip
                        label={order.credit > 0 ? order.credit : "0"}
                        sx={{
                          fontSize: 20, color: textColor,
                          bgcolor: order.credit > 0 ? (mode === 'light' ? '#FFDAB9' : '#FF8C00') : 'inherit',
                          borderRadius: '10px'
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <Tooltip title={<Typography style={{ fontSize: '17px' }}>פתיחת ההזמנה</Typography>} placement="top">
                        <IconButton color="primary" onClick={() => navigate(`/Orders_History/show/${order._id}`)}>
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={<Typography style={{ fontSize: '17px' }}>מחיקת ההזמנה</Typography>} style={{ fontSize: '50px', }} placement="top">
                        <IconButton color="error" onClick={() => setOrderToDelete(order._id)}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {orderToDelete === order._id && <ConfirmationDialog 
                    dialogState={setOrderToDelete}
                    yesFunction={() => handleClickOrderRemoved(order._id)}
                    message={`האם אתה מאשר למחוק הזמנה מספר: ${order.invoicingNum} ששיכת ללקוח: ${order.customerName}`}
                    />}
                  </TableRow>
                ))}
            </TableBody>
            )}
          </Table>
          {scrollLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
            <CircularProgress
              disableShrink
              size={60}
              thickness={5}
              style={{ marginTop: '20px' }}
            />
          </Box>
        )}
        </TableContainer>
      </Box>

      <Box sx={{ display: { md: 'none', xs: 'block' } }}>
        <Stack spacing={1} direction="column-reverse">
          {orders.length === 0 && <h4>אין הזמנות</h4>}
          {orders.filter((item) => {
              return (search.toLowerCase() === '' ? item :item.customerName.toLowerCase().includes(search)
              || item._id.toLowerCase().includes(search)) }).map((order: any) => (
            <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1 }} key={order._id}>
              <CardContent>
                <Typography fontSize='21px' fontWeight='bold' textAlign={'right'}>
                הזמנה מס": {order.invoicingNum.toString().padStart(6, '0')}
                </Typography>
                <Stack direction='row-reverse' justifyContent="space-between">
                  <Stack direction="column" justifyContent="flex-start" alignItems='flex-end'>
                    <Typography fontSize='21px' style={{ direction: 'rtl', }}>לקוח: {order.customerName} <Person/></Typography>
                    <Typography fontSize='21px'>{
                      new Date(order.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })
                    }</Typography>
                    {order.credit > 0 && <Typography sx={{fontSize:'21px', bgcolor:'#FBAC7A', borderRadius:'10px'}}>זיכוי: {order.credit} <AirlineStops/></Typography>}
                    {(!order.ifPayed && (<Typography align='center' fontWeight='bold' sx={{fontSize:'22px', bgcolor:'#FA8A79', borderRadius:'10px'}}>לא שולם <Payment/></Typography>))
                     || (order.ifPayed && (<Typography align='center' fontWeight='bold' sx={{fontSize:'22px', bgcolor:'#99FA60', borderRadius:'10px'}}>שולם <Payment/></Typography>))}
                  </Stack>
                  <CardActions>
                    <Button variant="text" sx={{ fontSize:18 ,background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                    onClick={() => navigate(`/Orders_History/show/${order._id}`)}>פרטים</Button>
                  </CardActions>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default OrdersHistory