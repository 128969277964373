import React from 'react'

const ProductDetails = () => {
  return (
    <div>
      ProductDetails
    </div>
  )
}

export default ProductDetails
