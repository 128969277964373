import { useLogout, useShow, useNotification, useDelete } from '@pankod/refine-core'
import { useNavigate, useParams } from '@pankod/refine-react-router-v6'
import { Box, Button, Card, CardContent, CircularProgress, Stack, Table, TableBody, TableCell,
   TableContainer, TableHead, TableRow, Typography } from '@pankod/refine-mui';
import FilesDialog from 'components/filesDialog';
import { Edit, Delete } from '@mui/icons-material';
import { useState } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import axios from 'axios';
import Dialog from 'components/dialog';
import { url } from '../assets/url'

const OrderDetails = () => {
  const { queryResult } = useShow();
  const { open, } = useNotification();
  const navigate = useNavigate();
  const { mutate } = useDelete();
  const { id } = useParams();
  const { data, isLoading, isError, refetch, isRefetching, } = queryResult;
  const { mutate: mutateLogout } = useLogout();
  const [openDialog, setOpenDialog] = useState(false);
  const [conformationDialog, setConformationDialog] = useState(false);
  const [currentId, setCurrentId] = useState(false);

  const order = data?.data;
  const products = order?.products

  if(isLoading || isRefetching  ) return <center><CircularProgress disableShrink /></center>
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  const getTotalPrice = (products : any) => {
    return products.reduce((totalPrice : any, product : any) => {
      const price = product.product.sellPrice;
      const chosenQuantity = product.chosenQuantity;
      const discount = (1 - (product.dedication/100));
      return totalPrice + (price * chosenQuantity * discount);
    }, 0);
  };

  const removeOrder = () => {
    setConformationDialog(true);
  }

  const handleClickOrderRemoved = (id: String) => {
    mutate(
      {
        resource: "Orders_History",
        id: id as string,
      },
      {
        onSuccess: () => {
          navigate("/Orders_History");
        },
      },
    );
  };

  const filesOnClickHandler = () => {
    setOpenDialog(true);
  };

  const handleDeleteProduct = async (product: any) => {
    try {
      const response = await axios.post(`${url}/Orders_History/deleteOrderProduct/${id}`, {removedProduct: product}, { withCredentials: true });
      if (response.status === 200) { 
        open?.({
          type: "success", 
          message: 'נמחק בהצלחה',
          undoableTimeout: 3,
        });
        refetch()
      }
    } catch (error:any) {
      console.error('Error:', error);
      open?.({
        type: "error",
        message: error.response?.data?.message || "שגיאה במחיקת המוצר",
        undoableTimeout: 6,
        description: "שגיאה",
      });
    }
  };
  

  return (
    <Box>
      <Stack direction='row-reverse' justifyContent='space-between'>
        <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>פרטי ההזמנה</Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 3 }}>
          <Button variant="contained" sx={{boxShadow: 3, borderRadius: 5, bgcolor: '#FF3737', color: '#FFFFFF'}} startIcon={<Delete/>} onClick={() => removeOrder()}>מחק</Button>
          <Button variant="contained" sx={{boxShadow: 3, borderRadius: 5}} startIcon={<Edit/>}
            onClick={() => navigate(`/Orders_History/edit/${order?._id}`)}>עריכה</Button>
          <Button sx={{boxShadow: 3, borderRadius: 5, bgcolor: '#63A3FD', color: '#FFFFFF'}} onClick={() => filesOnClickHandler()}>מסמכים</Button>
        </Stack>
      </Stack><br/>
      <Stack direction={{xs: 'column', sm: 'column', md: 'column', lg: 'row-reverse'}}
        justifyContent="space-between"
        alignItems="flex-end">
         <Typography sx={{fontSize: '22px', fontWeight: 'bold', direction: 'rtl', textAlign: 'right'}}>לקוח: {order?.customerName}</Typography>
         {(!order?.ifPayed && (<Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#FA8A79', borderRadius:'7px'}}>שולם: ₪{Number((getTotalPrice(products) * 1.17) * (1 - (order?.dedication/100))).toFixed(2)}\₪{Number(order?.Payed).toFixed(2)}</Typography>))
          || (order?.ifPayed && (<Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#99FA60', borderRadius:'7px'}}>שולם: ₪{Number((getTotalPrice(products) * 1.17) * (1 - (order?.dedication/100))).toFixed(2)}\₪{Number(order?.Payed).toFixed(2)}</Typography>))}
         <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>הזמנה מס": {order?.invoicingNum.toString().padStart(6, '0')}</Typography>
         {(order?.credit > 0 && <Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#FBAC7A', borderRadius:'7px'}}>זיכוי: {order?.credit}</Typography>)
         || <Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#99FA60', borderRadius:'7px'}}>זיכוי: {order?.credit}</Typography>}
         <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>{
            new Date(order?.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })
         }</Typography>
      </Stack>
      <Typography sx={{fontSize: '22px', fontWeight: 'bold', textAlign: 'right', direction: 'rtl',}}>תיאור: {order?.description}</Typography><br/>
      <Box sx={{display: { xs: 'none', md: 'block' }}}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{bgcolor: '#232323'}}>          
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיקה</TableCell> 
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>כולל מע"ם</TableCell>

              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>סה"כ</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>כמות</TableCell>

              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מ.אחרי הנחה</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>הנחה</TableCell>

              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר ליחיד</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מק"ט</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מוצר</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product : any) => (
              <TableRow key={product._id}>        
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                  <Button onClick={() => setCurrentId(product)} sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>
                </TableCell> 
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(product.chosenQuantity * product.product.sellPrice * (1 - (product.dedication/100)) * 1.17).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(product.chosenQuantity * product.product.sellPrice * (1 - (product.dedication/100))).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{product.chosenQuantity}</TableCell>

                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number((1 - (product.dedication/100)) * product.product.sellPrice).toFixed(2)}₪</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{product.dedication}</TableCell>

                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(product.product.sellPrice).toFixed(2)}₪</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', wordBreak: 'break-word', }} style={{ direction: 'rtl', }}>{product.product.productCode}</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', wordBreak: 'break-word', }} style={{ direction: 'rtl', }}>{product.product.name}</TableCell>
                {currentId === product && <Dialog title={`? האם אתה רוצה למחוק המוצר ${product.product.name}`} dialogState={setCurrentId} yesFunction={handleDeleteProduct} itemId={product}/>}
              </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      <Box sx={{display: { md: 'none', xs: 'block' }}}>
      <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
            {products.map((p : any) => (
              <Card sx={{ boxShadow: 2, borderRadius: 3, border: 1 }} key={p._id}>
                <CardContent>
                  <Stack direction='column' justifyContent="space-between" spacing={0.3}> 
                    <Typography fontSize={22} fontWeight='bold' style={{ direction: 'rtl', }} sx={{textAlign: 'right'}}>
                      {p.product.name}
                    </Typography>
                    <Typography sx={{fontSize: '19px', textAlign: 'right'}} style={{ direction: 'rtl', }}>מק"ט: {p.product.productCode}</Typography>
                    <Stack direction='row-reverse' justifyContent="space-between">
                      <Typography sx={{fontSize: '19px', textAlign: 'right'}}>מחיר ליחידה: ₪{p.product.sellPrice}</Typography>
                      <Typography sx={{fontSize: '19px', textAlign: 'right'}}>הנחה: {Number(p.dedication).toFixed(2)}%</Typography>
                    </Stack>
                    <Typography sx={{fontSize: '19px', textAlign: 'right'}}>מ.אחרי הנחה: {Number(p.product.sellPrice * (1-(Number(p.dedication)/100))).toFixed(2)}₪</Typography>
                    <Typography sx={{fontSize: '19px', textAlign: 'right' }}>כמות: {p.chosenQuantity}</Typography>
                    <Typography sx={{fontSize: '19px', fontWeight: 'bold', textAlign: 'left'}}>סכ"ה: ₪{Number(p.chosenQuantity * p.product.sellPrice * (1-(p.dedication/100))).toFixed(2)}</Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Stack>
      </Box>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}} align="right">{Number(getTotalPrice(products)).toFixed(2)}</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}}>:סה"כ ללא מע"ם</TableCell>
      </TableRow>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}} align="right">{order?.dedication.toFixed(3)}%</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}}>:הנחה</TableCell>
        </TableRow>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}} align="right">{Number((getTotalPrice(products)) * (1 -  (order?.dedication/100))).toFixed(2)}</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}}>:סה"כ לאחר הנחה</TableCell>
      </TableRow>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}} align="right">{Number((getTotalPrice(products)) * (1 -  (order?.dedication/100)) * 0.17).toFixed(2)}</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}}>:מע"ם 17.00%</TableCell>
      </TableRow>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}} align="right">{Number((getTotalPrice(products)) * (1 -  (order?.dedication/100)) * 1.17).toFixed(2)}</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold'}}>:מחיר סופי לתשלום </TableCell>
      </TableRow>
      {openDialog && <FilesDialog dialogState={setOpenDialog} itemId={order?._id}/>}
      {conformationDialog && <ConfirmationDialog dialogState={setConformationDialog} yesFunction={() => handleClickOrderRemoved(order?._id)} message={`האם אתה מאשר למחוק הזמנה מספר: ${order?.invoicingNum} ?`}/>}
    </Box>
  )
}

export default OrderDetails