import { Add, Apartment, Search } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { useLogout, useTable } from '@pankod/refine-core'
import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { useContext, useState,  } from 'react'
import { ColorModeContext } from "contexts";


const Suppliers = () => {
  const { mode } = useContext(ColorModeContext);
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === 'light' ? 'black' : 'white';

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { mutate: mutateLogout } = useLogout();
  
  const {
    tableQueryResult: {data, isLoading, isError},
  } = useTable();
  
  const allSuppliers = data?.data ?? [];

  if(isLoading) return <center><CircularProgress disableShrink /></center>
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }
  
  return (
    <Box style={{ overflowX: "hidden" }}>
      <Stack direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={12}>
        <Typography fontSize='25px' fontWeight='bold' color={textColor}>
            ספקים
        </Typography>
        <Button sx={{boxShadow: 3, borderRadius: 10}} variant="contained" endIcon={<Add/>}
         onClick={() => navigate('/Suppliers/create')}>הוספת ספק</Button>
      </Stack><br/>
      <TextField
            fullWidth
            id="input-with-icon-textfield"
            label="חיפוש"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={(e) => {setSearch(e.target.value)}}
            inputProps={{ style: { direction: 'rtl', } }}
      /><br/><br/>

      <TableContainer sx={{ display: { xs: 'none', md: 'block' }, direction: 'rtl', }}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: tableHead }}>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>שם חברה</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>מס" חברה</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>מספר טל</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>חוב+</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>זיכוי-</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>סכום=</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>תיק החברה</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allSuppliers.filter((item) => {
              return search.toLowerCase() === '' ? item :item.companyName.toLowerCase().includes(search.toLowerCase())
              || item.phoneNumber.toLowerCase().includes(search.toLowerCase()) || item.companyNumber.toLowerCase().includes(search.toLowerCase())
            }).map((supplier) => (
              <TableRow key={supplier._id}>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{supplier?.companyName}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{supplier?.companyNumber}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{supplier?.phoneNumber}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}><Chip color={supplier?.dept ? 'error' : 'primary'} label={`${Number(supplier?.dept).toFixed(2)}₪`} sx={{ fontSize: 20 }} /></TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}><Chip color={supplier?.credit ? 'warning' : 'primary'} label={`${Number(supplier?.credit).toFixed(2)}₪`} sx={{ fontSize: 20 }} /></TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{Number(supplier?.dept - supplier?.credit).toFixed(2)}₪</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>
                  <Button variant="text" sx={{background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                    onClick={() => navigate(`/Suppliers/show/${supplier._id}`)}><Apartment/></Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{display: { md: 'none', xs: 'block' }}}>
        <Stack
            direction="column-reverse"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
            {allSuppliers.filter((item) => {
              return search.toLowerCase() === '' ? item :item.companyName.toLowerCase().includes(search.toLowerCase())
              || item.phoneNumber.toLowerCase().includes(search.toLowerCase()) || item.companyNumber.toLowerCase().includes(search.toLowerCase())
            }).map((supplier) => (
              <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1, }} key={supplier._id}>
                <CardContent>
                  <Typography fontSize={25} fontWeight='bold' textAlign={'right'}>
                    {supplier.companyName}
                  </Typography>
                  <Stack direction="row-reverse" justifyContent="space-between"> 
                    <Typography variant="body2">
                      <Stack spacing={0.5} direction="column" justifyContent="flex-start">
                        <Typography sx={{fontSize: '21px', direction: 'rtl'}}>מס" חברה: {supplier.companyNumber}</Typography>
                        <Typography sx={{fontSize: '21px', direction: 'rtl'}}>טל: {supplier.phoneNumber}</Typography>
                        {(supplier.credit > 0 && <Typography sx={{fontSize: '21px', bgcolor:'#F48E54', borderRadius:'10px', textAlign:'center', direction: 'rtl'}}>זיכוי: ₪{supplier.credit}</Typography>)}
                        {(supplier.dept > 0 && <Typography sx={{fontSize: '21px', bgcolor:'#FA8A79', borderRadius:'10px', textAlign:'center', direction: 'rtl'}}>חוב: ₪{Number(supplier.dept).toFixed(2)}</Typography>)
                        ||(supplier.dept === 0 && <Typography sx={{fontSize: '21px', bgcolor:'#A6FD92', borderRadius:'10px', textAlign:'center', direction: 'rtl'}}>חוב: ₪{supplier.dept}</Typography>)}
                      </Stack>
                    </Typography>
                    <CardActions>
                      <Button variant="text" sx={{background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                       onClick={() => navigate(`/Suppliers/show/${supplier._id}`)}><Apartment/>היסטוריה</Button>
                    </CardActions>
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default Suppliers