import { Add, AirlineStops, Payment, Search, Apartment, Visibility, Delete } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'
import { useLogout, useTable, useDelete } from '@pankod/refine-core'
import { Button, CircularProgress, Typography, CardContent, CardActions, Card, TextField, InputAdornment, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Chip, IconButton, Tooltip} from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { useState, useContext } from 'react'
import { ColorModeContext } from "contexts";
import ConfirmationDialog from 'components/ConfirmationDialog'

const Supplies = () => {
  const { mode } = useContext(ColorModeContext);
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === 'light' ? 'black' : 'white';

  const navigate = useNavigate();
  const { mutate } = useDelete();
  const [search, setSearch] = useState('');
  const { mutate: mutateLogout } = useLogout();
  const [supplyToDelete, setSupplyToDelete] = useState(false);

  const {
    tableQueryResult: {data, isLoading, isError}
  } = useTable();
  
  const supplies = data?.data ?? [];

  if(isLoading) return <center><CircularProgress disableShrink /></center>
  
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  
  const handleClickSupplyRemoved = (id: String) => {
    mutate(
      {
        resource: "Supplies",
        id: id as string,
      },
      {
        onSuccess: () => {
          navigate("/Supplies");
        },
      },
    );
  }; 

  return (
    <Box style={{ overflowX: "hidden" }}>
      <Stack direction='row-reverse'
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={12}>
        <Typography textAlign='right' fontSize='25px' fontWeight='bold' color={textColor}>
            הזמנות מספקים
        </Typography>
        <Button variant="contained" endIcon={<Add/>} sx={{boxShadow: 3, borderRadius: 5}}
         onClick={() => navigate('/Supplies/create')}>הוספת הזמנה</Button>
      </Stack><br/>
      <TextField
        fullWidth
        id="input-with-icon-textfield"
        label="חיפוש"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={(e) => {setSearch(e.target.value.toLowerCase())}}
        inputProps={{ style: { direction: 'rtl', } }}
      /><br/><br/>

      <Box sx={{ display: { xs: 'none', md: 'block' }}}>
        <TableContainer sx={{ direction: 'rtl', }}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead sx={{ bgcolor: tableHead }}>
              <TableRow>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor, }}>מספר הזמנה</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor, }}>ספק</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor, }}>זמן ההזמנה</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor, }}>סטטוס</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor, }}>זיכוי</TableCell>
                <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21, color: textColor, }}>פעולות</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {supplies.length === 0 && <h4>אין הזמנות</h4>}
              {supplies.slice().reverse().filter((item) => {
              return search.toLowerCase() === '' ? item :item.invoicingNum.toString().toLowerCase().includes(search.toLowerCase())
            }).map((supply: any) => (
              <TableRow key={supply._id}>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{supply?.invoicingNum}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{supply?.supplier?.companyName}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{new Date(supply.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {supply?.ifPayed ? (
                      <Chip label="שולם" sx={{ fontSize: '19px', bgcolor: '#99FA60',  }} />
                    ) : (
                      <Chip label="לא שולם" sx={{ fontSize: '19px', bgcolor: '#FA8A79', borderRadius: '10px' }} />
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Chip
                    label={supply.credit > 0 ? supply.credit : "0"}
                    sx={{
                      color: textColor,
                      fontSize: 20,
                      bgcolor: supply.credit > 0 ? (mode === 'light' ? '#FFDAB9' : '#FF8C00') : 'inherit',
                      borderRadius: '10px'
                    }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Tooltip title={<Typography style={{ fontSize: '17px', }}>פתיחת ההזמנה</Typography>} placement="top">
                    <IconButton color="primary" onClick={() => navigate(`/Supplies/show/${supply._id}`)}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Typography style={{ fontSize: '17px', }}>מחיקת ההזמנה</Typography>} style={{ fontSize: '50px', }} placement="top">
                    <IconButton color="error" onClick={() => setSupplyToDelete(supply._id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                {supplyToDelete === supply._id && <ConfirmationDialog 
                  dialogState={setSupplyToDelete}
                  yesFunction={() => handleClickSupplyRemoved(supply._id)}
                  message={`האם אתה מאשר למחוק הזמנה מספר: ${supply?.invoicingNum} ששיכת להספק: ${supply?.supplier?.companyName}`}
                />}
              </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      
      <Box sx={{ display: { md: 'none', xs: 'block' } }}>
        <Stack spacing={1} direction="column-reverse">
          {supplies.length === 0 && <h4>אין הזמנות</h4>}
          {supplies.filter((item) => {
              return search.toLowerCase() === '' ? item :item.invoicingNum.toString().toLowerCase().includes(search.toLowerCase())
            }).map((supply: any) => (
            <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1 }} key={supply._id}>
              <CardContent>
                <Typography fontSize='21px' fontWeight='bold' textAlign={'right'}>
                  {supply.invoicingNum}
                </Typography>
                <Stack direction='row-reverse' justifyContent="space-between">
                  <Stack direction="column" justifyContent="flex-start" alignItems='flex-end'>
                    <Typography fontSize='21px'>ספק: {supply.supplier.companyName} <Apartment/></Typography>
                    <Typography fontSize='21px'>{new Date(supply.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</Typography>
                    {supply.credit > 0 && <Typography sx={{fontSize:'21px', bgcolor:'#FBAC7A', borderRadius:'10px'}}>זיכוי: {supply.credit} <AirlineStops/></Typography>}
                    {(!supply.ifPayed && <Typography align='center' fontWeight='bold' sx={{fontSize:'22px', bgcolor:'#FA8A79', borderRadius:'10px'}}>לא שולם <Payment/></Typography>)
                    || (supply.ifPayed && <Typography align='center' fontWeight='bold' sx={{fontSize:'22px', bgcolor:'#99FA60', borderRadius:'10px'}}>שולם <Payment/></Typography>)}
                  </Stack>
                  <CardActions>
                    <Button variant="text" sx={{ fontSize:18 ,background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                    onClick={() => navigate(`/Supplies/show/${supply._id}`)}>פרטים</Button>
                  </CardActions>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default Supplies;