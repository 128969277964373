import { Add, Person, Search } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { useLogout, useTable } from '@pankod/refine-core'
import { Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { useState, useContext } from 'react'
import { ColorModeContext } from "contexts";

const Customers = () => {
  const { mode } = useContext(ColorModeContext);
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === 'light' ? 'black' : 'white';

  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const { mutate: mutateLogout } = useLogout();

  const {
    tableQueryResult: {data, isLoading, isError},
  } = useTable();
  
  const allCustomers = data?.data ?? [];

  if(isLoading) return <center><CircularProgress disableShrink /></center>
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }
  
  return (
    <Box style={{ overflowX: "hidden" }}>
      <Stack direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={12}>
        <Typography fontSize='25px'
          fontWeight='bold'
          color={textColor}
        >
            לקוחות
        </Typography>
        <Button sx={{boxShadow: 3, borderRadius: 10}} variant="contained" endIcon={<Add/>}
         onClick={() => navigate('/Customers/create')}>הוספת לקוח</Button>
      </Stack><br/>

      <TextField
        fullWidth
        id="input-with-icon-textfield"
        label="חיפוש"
        InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        }}
        variant="outlined"
        onChange={(e) => {setSearch(e.target.value)}}
        inputProps={{ style: { direction: 'rtl', unicodeBidi: 'plaintext' } }}
      /><br/><br/>

      <TableContainer sx={{ display: { xs: 'none', md: 'block' }, direction: 'rtl',}}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: tableHead }}>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>שם לקוח</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>מספר טל</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>ח.פ</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>הזמנות</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>חוב+</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>זיכוי-</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor, }}>סכום=</TableCell>
              <TableCell sx={{ textAlign: 'center', fontSize: 21, fontWeight: 'bold', color: textColor }}>תיק הלקוח</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {allCustomers.filter((item) => {
              return search.toLowerCase() === '' ? item :item.name.toLowerCase().includes(search.toLowerCase())
              || item.phoneNumber.toLowerCase().includes(search.toLowerCase())
            }).map((customer) => (
              <TableRow>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{customer.name}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{customer.phoneNumber}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{customer.customerNumber ? customer.customerNumber : "אין"}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>{customer?.allOrders?.length}</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}><Chip color={customer?.dept ? 'error' : 'primary'} label={`${Number(customer?.dept).toFixed(2)}₪`} sx={{ fontSize: 20 }} /></TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}><Chip color={customer?.credit ? 'warning' : 'primary'} label={`${Number(customer?.credit).toFixed(2)}₪`} sx={{ fontSize: 20 }} /></TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor, }}>{Number(customer?.dept - customer?.credit).toFixed(2)}₪</TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: 20, color: textColor }}>
                  <Button variant="text" sx={{background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                       onClick={() => navigate(`/Customers/show/${customer._id}`)}><Person/></Button>
                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{display: { md: 'none', xs: 'block' }}}>
        <Stack 
            direction="column-reverse"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
            {allCustomers.filter((item) => {
              return search.toLowerCase() === '' ? item :item.name.toLowerCase().includes(search.toLowerCase())
              || item.phoneNumber.toLowerCase().includes(search.toLowerCase())
            }).map((customer) => (
              <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1, }} key={customer._id}>
                <CardContent>
                  <Typography fontSize={25} fontWeight='bold' textAlign={'right'}>
                    {customer.name}
                  </Typography>
                  <Stack direction="row-reverse" justifyContent="space-between"> 
                    <Typography variant="body2">
                      <Stack spacing={0.5} direction="column" justifyContent="flex-start">
                        <Typography sx={{ fontSize: '21px',direction: 'rtl'}} >טל: {customer?.phoneNumber}</Typography>
                        {customer.customerNumber && <Typography sx={{fontSize: '21px', direction: 'rtl'}} >ח.פ: {customer.customerNumber}</Typography>}
                        {(customer.credit > 0 && <Typography sx={{fontSize: '21px', bgcolor:'#F48E54', borderRadius:'10px', textAlign:'center', direction: 'rtl'}}>זיכוי: ₪{Number(customer.credit).toFixed(2)}</Typography>)}
                        <Typography sx={{ fontSize: '21px', textAlign:'right',direction: 'rtl'}} >הזמנות: {customer.allOrders.length}</Typography>
                        {(customer.dept > 0 && <Typography sx={{fontSize: '21px', bgcolor:'#FA8A79', borderRadius:'10px', textAlign:'center', direction: 'rtl'}} >חוב: ₪{Number(customer.dept).toFixed(2)}</Typography>)
                        ||(customer.dept === 0 && <Typography sx={{fontSize: '21px', bgcolor:'#A6FD92', borderRadius:'10px', textAlign:'center', direction: 'rtl'}} >חוב: ₪{customer.dept}</Typography>)}
                      </Stack>
                    </Typography>
                    <CardActions>
                      <Button variant="text" sx={{background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                       onClick={() => navigate(`/Customers/show/${customer._id}`)}><Person/>היסטוריה</Button>
                    </CardActions>
                  </Stack>
                </CardContent>
              </Card>
            ))}
        </Stack>
      </Box>
    </Box>
  )
}

export default Customers