import { Add, Delete, Search, Autorenew, Edit } from '@mui/icons-material';
import { useDelete, useLogout, useTable } from '@pankod/refine-core';
import { Box, Button, Card, CardContent, Chip, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6';
import Dialog from '../components/dialog';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import React, { useState, useContext } from 'react'
import { ColorModeContext } from "contexts";
import axios from 'axios';
import { url } from 'assets/url';

function Checks() {
  const { mode } = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [month, setMonth] = useState<Number>(0);
  const [year, setYear] = useState<Number>(0);
  const { mutate: mutateLogout } = useLogout();
  const [currentId, setCurrentId] = useState(false);
  const { mutate } = useDelete();
  const [value, setValue] = useState(0);

  const backgroundColor = mode === "light" ? "#343434" : "#FFFFFF";
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === 'light' ? 'black' : 'white';

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  }

  const handleDeleteCheck = (id: String) => {
    mutate(
      {
          resource: "Checks",
          id: id as string,
      },
      {
          onSuccess: () => {
              navigate("/Checks");
          },
      },
    );
  };

  const {
    tableQueryResult: {data, isLoading, isError, refetch, isRefetching},
  } = useTable();

  const allChecks = data?.data ?? [];

  if(isLoading || isRefetching) return <center><CircularProgress disableShrink /></center>
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  const totalChecksSum = allChecks.filter((check: any) => {
    if (month !== null && month !== 0 && check?.date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check?.date?.year !== year) {
      return false;
    }
    if (check.receive) { return false }
    const lowerCaseQuery = search.toLowerCase();
    return (
      check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
      check.account.toLowerCase().includes(lowerCaseQuery) ||
      check.person.toLowerCase().includes(lowerCaseQuery)
    );
  }).reduce((total, check) => total + check.price, 0);

  const totalPayedChecksSum = allChecks.filter((check: any) => {
    if (month !== null && month !== 0 && check?.date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check?.date?.year !== year) {
      return false;
    }
    if (check.receive) { return false }
    if (!check.ifPayed) { return false }
    const lowerCaseQuery = search.toLowerCase();
    return (
      check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
      check.account.toLowerCase().includes(lowerCaseQuery) ||
      check.person.toLowerCase().includes(lowerCaseQuery)
    );
  }).reduce((total, check) => total + check.price, 0);

  const totalNotPayedChecksSum = allChecks.filter((check: any) => {
    if (month !== null && month !== 0 && check?.date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check?.date?.year !== year) {
      return false;
    }
    if (check.receive) { return false }
    if (check.ifPayed) { return false }
    const lowerCaseQuery = search.toLowerCase();
    return (
      check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
      check.account.toLowerCase().includes(lowerCaseQuery) ||
      check.person.toLowerCase().includes(lowerCaseQuery)
    );
  }).reduce((total, check) => total + check.price, 0);

  // ===============================================
  const totalPayChecksSum = allChecks.filter((check: any) => {
    if (month !== null && month !== 0 && check?.date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check?.date?.year !== year) {
      return false;
    }
    if (!check.receive) { return false }
    const lowerCaseQuery = search.toLowerCase();
    return (
      check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
      check.account.toLowerCase().includes(lowerCaseQuery) ||
      check.person.toLowerCase().includes(lowerCaseQuery)
    );
  }).reduce((total, check) => total + check.price, 0);

  const totalPayPayedChecksSum = allChecks.filter((check: any) => {
    if (month !== null && month !== 0 && check?.date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check?.date?.year !== year) {
      return false;
    }
    if (!check.receive) { return false }
    if (!check.ifPayed) { return false }
    const lowerCaseQuery = search.toLowerCase();
    return (
      check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
      check.account.toLowerCase().includes(lowerCaseQuery) ||
      check.person.toLowerCase().includes(lowerCaseQuery)
    );
  }).reduce((total, check) => total + check.price, 0);

  const totalPayNotPayedChecksSum = allChecks.filter((check: any) => {
    if (month !== null && month !== 0 && check?.date?.month !== month) {
      return false;
    }
    if (year !== null && year !== 0 && check?.date?.year !== year) {
      return false;
    }
    if (!check.receive) { return false }
    if (check.ifPayed) { return false }
    const lowerCaseQuery = search.toLowerCase();
    return (
      check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
      check.account.toLowerCase().includes(lowerCaseQuery) ||
      check.person.toLowerCase().includes(lowerCaseQuery)
    );
  }).reduce((total, check) => total + check.price, 0);

  return (
    <Box style={{ overflowX: "hidden" }}>
      <Stack direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={12}>
        <Typography fontSize='25px' fontWeight='bold' color={textColor}>
            צי"קים
        </Typography>
        <Button sx={{boxShadow: 3, borderRadius: 10}} variant="contained" endIcon={<Add/>}
         onClick={() => navigate('/Checks/create')}>הוספת צ"יק</Button>
      </Stack><br/>
      <Stack direction={{ xs: 'column', sm: 'row-reverse' }} spacing={2} justifyContent="space-between">
        <TextField
          fullWidth
          id="input-with-icon-textfield"
          label="חיפוש"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={(e) => {setSearch(e.target.value)}}
          inputProps={{ style: { direction: 'rtl', unicodeBidi: 'plaintext' } }}
        />
        <TextField sx={{ minWidth: '100px', display: { xs: 'none', sm: 'block' } }} label="שנה" type="number" variant="outlined" value={year} onChange={(e) => setYear(Number(e.target.value))}/>
        <FormControl sx={{ width: '100px', display: { xs: 'none', sm: 'block' } }}>
          <InputLabel id="demo-simple-select-label">חודש</InputLabel>
          <Select sx={{ minWidth: '75px', }}
            label="חודש"
            value={month}
            labelId="חודש"
            onChange={(e) => setMonth(e.target.value as Number)}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </FormControl>
        <IconButton aria-label="delete" 
          onClick={() => { 
            setMonth(0) 
            setYear(0)}}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          <RestartAltIcon />
        </IconButton>
      </Stack><br/>

      <Tabs         
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="basic tabs example"
        variant="fullWidth"
        centered
        sx={{ direction: 'rtl' }}>
          <Tab label="צ'יקים שמקבל" sx={{ fontSize: '20px', fontWeight: 'bold', color: backgroundColor }}/>
          <Tab label="צ'יקים שמשלם" sx={{ fontSize: '20px', fontWeight: 'bold', color: backgroundColor }}/>
        </Tabs>

      <TabPanel value={value} index={0}>
        {/* PC Display Version */}
        <TableContainer sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: tableHead }}>
              <TableRow>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>פעולות</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>איש</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>מצב</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>סכום</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>תאריך פרעון</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>תאריך כתיבה</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>חשבון</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>בנק</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>מספר צ"יק</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allChecks.filter((check:any) => check.receive).filter((check:any) => {
                if (month && check.date?.month !== month) {
                  return false;
                }
                if (year && check.date?.year !== year) {
                  return false;
                }
                const lowerCaseQuery = search.toLowerCase();
                return (
                  check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
                  check.account.toLowerCase().includes(lowerCaseQuery) ||
                  check.person.toLowerCase().includes(lowerCaseQuery)
                );
              }).map((check) => (
                <TableRow key={check._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="right">
                    <Tooltip title={
                      <Typography style={{ fontSize: '17px', direction: 'rtl', textAlign: 'right' }}>
                        לשנות מצב תשלום הציק
                      </Typography>
                    }>
                      <Button onClick={() => {
                          axios.post(`${url}/checks/updateCheckStatus`, 
                          {id: check._id},
                          {withCredentials: true, responseType: 'json'}).then((res) => {
                              refetch()
                          })
                      }}
                      sx={{borderRadius: '30px', backgroundColor: '#40E200', boxShadow: 2, color: 'white'}}>
                        <Autorenew />
                      </Button>
                    </Tooltip>
                  
                    <Button onClick={() => setCurrentId(check._id)}
                      sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>
                    <Button onClick={() => navigate(`/Checks/edit/${check._id}`)}
                        sx={{borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white'}}><Edit/></Button>
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, }}>
                    {(check.person) ? `${check.person}` : "אין"}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, }}>  
                  {check.ifPayed ? (
                      <Chip
                        label="שולם"
                        color="success"
                      />
                    ) : (
                      <Chip
                        label="לא שולם"
                        color="error"
                      />
                    )}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.price}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check?.date?.day}/{check?.date?.month}/{check?.date?.year}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check?.takeDate?.day}/{check?.takeDate?.month}/{check?.takeDate?.year}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.account}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.bank} {check.branch}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor }}>
                    {check.description ? (
                      <Tooltip 
                        title={
                          <Typography style={{ fontSize: '17px', direction: 'rtl', textAlign: 'right' }}>
                            {check.description}
                          </Typography>
                        }
                        placement="left"
                      >
                        <span>{check.number}</span>
                      </Tooltip> 
                    ) : (
                      check.number
                    )}
                  </TableCell>
                  {currentId === check._id && <Dialog title={`האם אתה רוצה למחוק צ"יק מספר ${check.number}`} dialogState={setCurrentId} yesFunction={handleDeleteCheck} itemId={check._id}/>}
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right" sx={{ fontSize: 20, fontWeight: 'bold'}}>{totalPayPayedChecksSum}₪</TableCell>
                <TableCell colSpan={7} sx={{ fontSize: 20, fontWeight: 'bold', direction: 'rtl'}}>סכום הצ"יק ששולמו:</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ fontSize: 20, fontWeight: 'bold'}}>{totalPayNotPayedChecksSum}₪</TableCell>
                <TableCell colSpan={7} sx={{ fontSize: 20, fontWeight: 'bold', direction: 'rtl'}}>סכום הצ"יק שלא שולמו:</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ fontSize: 20, fontWeight: 'bold'}}>{totalPayChecksSum}₪</TableCell>
                <TableCell colSpan={7} sx={{ fontSize: 20, fontWeight: 'bold', direction: 'rtl'}}>סכום כל הצ"יק:</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
                    
        {/* Phone Display Version */}
        <Box sx={{ mt: 1, display: { xs: 'block', sm: 'block', md: 'none' }}}>
          {allChecks.filter((check:any) => check.receive).filter((check) => {
              if (month !== null && month !== 0 && check?.date?.month !== month) {
                return false;
              }
              if (year !== null && year !== 0 && check?.date?.year !== year) {
                return false;
              }
              const lowerCaseQuery = search.toLowerCase();
              return (
                check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
                check.account.toLowerCase().includes(lowerCaseQuery) ||
                check.person.toLowerCase().includes(lowerCaseQuery)
              );
            }).map((check) => (
              <Card key={check._id} sx={{ border: 1, boxShadow: 5, borderRadius: '17px', marginBottom: 2, direction: 'rtl' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 20 }}>מספר צ"יק: {check.number}</Typography>
                  <Typography sx={{ fontSize: 20 }}>בנק: {check.bank} - {check.branch}</Typography>
                  <Typography sx={{ fontSize: 20 }}>חשבון: {check.account}</Typography>
                  <Typography sx={{ fontSize: 20 }}>תאריך פרעון: {check?.date?.day}/{check?.date?.month}/{check?.date?.year}</Typography>
                  <Typography sx={{ fontSize: 20 }}>תאריך כתיבה: {check?.takeDate?.day}/{check?.takeDate?.month}/{check?.takeDate?.year}</Typography>
                  <Typography sx={{ fontSize: 20 }}>סכום: {check.price}</Typography>
                  <Typography sx={{ fontSize: 20 }}>איש: {check.person ? `${check.person}` : 'אין'}</Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'right' }}>
                      {check.ifPayed ? (
                        <Chip label="שולם" color="success" />
                      ) : (
                        <Chip label="לא שולם" color="error" />
                      )}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        onClick={() => {
                          axios.post(
                            `${url}/checks/updateCheckStatus`,
                            { id: check._id },
                            { withCredentials: true, responseType: 'json' }
                          ).then((res) => {
                            refetch()
                          });
                        }}
                        sx={{ borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white' }}
                      >
                        <Autorenew />
                      </IconButton>
                      <IconButton
                        onClick={() => setCurrentId(check._id)}
                        sx={{ borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white' }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {currentId === check._id && (
                    <Dialog title={`האם אתה רוצה למחוק צ"יק מספר ${check.number}`} dialogState={setCurrentId} yesFunction={handleDeleteCheck} itemId={check._id} />
                  )}
                </CardContent>
              </Card>
            ))
          }
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1}>
        {/* PC Display Version */}
        <TableContainer sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ bgcolor: tableHead }}>
              <TableRow>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>פעולות</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>איש</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>מצב</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>סכום</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor, }}>תאריך פרעון</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>תאריך כתיבה</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>חשבון</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>בנק</TableCell>
                <TableCell align="right" sx={{fontSize: 21, fontWeight: 'bold', color: textColor}}>מספר צ"יק</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allChecks.filter((check:any) => !check.receive).filter((check:any) => {
                if (month && check.date?.month !== month) {
                  return false;
                }
                if (year && check.date?.year !== year) {
                  return false;
                }
                const lowerCaseQuery = search.toLowerCase();
                return (
                  check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
                  check.account.toLowerCase().includes(lowerCaseQuery) ||
                  check.person.toLowerCase().includes(lowerCaseQuery)
                );
              }).map((check) => (
                <TableRow key={check._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="right">
                    <Tooltip title={
                      <Typography style={{ fontSize: '17px', direction: 'rtl', textAlign: 'right' }}>
                        לשנות מצב תשלום הציק
                      </Typography>
                    }>
                      <Button onClick={() => {
                          axios.post(`${url}/checks/updateCheckStatus`, 
                          {id: check._id},
                          {withCredentials: true, responseType: 'json'}).then((res) => {
                              refetch()
                          })
                      }}
                      sx={{borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white'}}>
                        <Autorenew />
                      </Button>
                    </Tooltip>
                  
                    <Button onClick={() => setCurrentId(check._id)}
                      sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>
                    <Button onClick={() => navigate(`/Checks/edit/${check._id}`)}
                      sx={{borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white'}}><Edit/></Button>
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, }}>
                    {(check.person) ? `${check.person}` : "אין"}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, }}>  
                  {check.ifPayed ? (
                      <Chip
                        label="שולם"
                        color="success"
                      />
                    ) : (
                      <Chip
                        label="לא שולם"
                        color="error"
                      />
                    )}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.price}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.date.day}/{check.date.month}/{check.date.year}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check?.takeDate?.day}/{check?.takeDate?.month}/{check?.takeDate?.year}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.account}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>{check.bank} {check.branch}</TableCell>
                  <TableCell align="right" sx={{ fontSize: 20, color: textColor, }}>
                    {check.description ? (
                      <Tooltip 
                        title={
                          <Typography style={{ fontSize: '17px', direction: 'rtl', textAlign: 'right' }}>
                            {check.description}
                          </Typography>
                        }
                        placement="left"
                      >
                        <span>{check.number}</span>
                      </Tooltip> 
                    ) : (
                      check.number
                    )}
                  </TableCell>
                  {currentId === check._id && <Dialog title={`האם אתה רוצה למחוק צ"יק מספר ${check.number}`} dialogState={setCurrentId} yesFunction={handleDeleteCheck} itemId={check._id}/>}
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="right" sx={{ fontSize: 20, fontWeight: 'bold'}}>{totalPayedChecksSum}₪</TableCell>
                <TableCell colSpan={7} sx={{ fontSize: 20, fontWeight: 'bold', direction: 'rtl'}}>סכום הצ"יק ששולמו:</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ fontSize: 20, fontWeight: 'bold'}}>{totalNotPayedChecksSum}₪</TableCell>
                <TableCell colSpan={7} sx={{ fontSize: 20, fontWeight: 'bold', direction: 'rtl'}}>סכום הצ"יק שלא ששולמו:</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" sx={{ fontSize: 20, fontWeight: 'bold'}}>{totalChecksSum}₪</TableCell>
                <TableCell colSpan={7} sx={{ fontSize: 20, fontWeight: 'bold', direction: 'rtl'}}>סכום כל הצ"יק:</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
                    
        {/* Phone Display Version */}
        <Box sx={{ mt: 1, display: { xs: 'block', sm: 'block', md: 'none' }}}>
          {allChecks.filter((check:any) => !check.receive).filter((check) => {
              if (month !== null && month !== 0 && check?.date?.month !== month) {
                return false;
              }
              if (year !== null && year !== 0 && check?.date?.year !== year) {
                return false;
              }
              const lowerCaseQuery = search.toLowerCase();
              return (
                check.number.toString().toLowerCase().includes(lowerCaseQuery) ||
                check.account.toLowerCase().includes(lowerCaseQuery) ||
                check.person.toLowerCase().includes(lowerCaseQuery)
              );
            }).map((check) => (
              <Card key={check._id} sx={{ border: 1, boxShadow: 5, borderRadius: '17px', marginBottom: 2, direction: 'rtl' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 20 }}>מספר צ"יק: {check.number}</Typography>
                  <Typography sx={{ fontSize: 20 }}>בנק: {check.bank} - {check.branch}</Typography>
                  <Typography sx={{ fontSize: 20 }}>חשבון: {check.account}</Typography>
                  <Typography sx={{ fontSize: 20 }}>תאריך כתיבה: {check?.date?.day}/{check?.date?.month}/{check?.date?.year}</Typography>
                  <Typography sx={{ fontSize: 20 }}>תאריך פרעון: {check.date.day}/{check.date.month}/{check.date.year}</Typography>
                  <Typography sx={{ fontSize: 20 }}>סכום: {check.price}</Typography>
                  <Typography sx={{ fontSize: 20 }}>איש: {check.person ? `${check.person}` : 'אין'}</Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold', textAlign: 'right' }}>
                      {check.ifPayed ? (
                        <Chip label="שולם" color="success" />
                      ) : (
                        <Chip label="לא שולם" color="error" />
                      )}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        onClick={() => {
                          axios.post(
                            `${url}/checks/updateCheckStatus`,
                            { id: check._id },
                            { withCredentials: true, responseType: 'json' }
                          ).then((res) => {
                            refetch()
                          });
                        }}
                        sx={{ borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white' }}
                      >
                        <Autorenew />
                      </IconButton>
                      <IconButton
                        onClick={() => setCurrentId(check._id)}
                        sx={{ borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white' }}
                      >
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Stack>
                  {currentId === check._id && (
                    <Dialog title={`האם אתה רוצה למחוק צ"יק מספר ${check.number}`} dialogState={setCurrentId} yesFunction={handleDeleteCheck} itemId={check._id} />
                  )}
                </CardContent>
              </Card>
            ))
          }
        </Box>
      </TabPanel>

    </Box>
  )
}

export default Checks

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, } = props;

  if (value === index) {
    // Display the content of this tab panel when it's active
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {children}
      </div>
    );
  } else {
    // Hide the content when it's not active
    return null;
  }
}