import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { DialogContent, TextField, Typography, Checkbox, FormControlLabel, Stack } from '@pankod/refine-mui'
import { useState } from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MenuDialog({ dialogState, yesFunction }) {

    const [fromDateDay, setFromDateDay] = useState(1);
    const [fromDateMonth, setFromDateMonth] = useState((new Date()).getMonth() + 1);
    const [fromDateYear, setFromDateYear] = useState((new Date()).getFullYear());

    const [toDateDay, setToDateDay] = useState(30);
    const [toDateMonth, setToDateMonth] = useState((new Date()).getMonth() + 1);
    const [toDateYear, setToDateYear] = useState((new Date()).getFullYear());

    const [fullyPayed, setFullyPayed] = useState(true);
    const [partPayed, setPartPayed] = useState(true);
    const [notPayed, setNotPayed] = useState(true);

    const handleFromDateDayChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value)) {
          setFromDateDay(Number(value));
        }
    }
    const handleFromDateMonthChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value)) {
          setFromDateMonth(Number(value));
        }        
    }
    const handleFromDateYearChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value)) {
          setFromDateYear(Number(value));
        }        
    }
    const handleToDateDayChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value)) {
          setToDateDay(Number(value));
        }       
    }
    const handleToDateMonthChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value)) {
          setToDateMonth(Number(value));
        }        
    }
    const handleToDateYearChange = (event) => {
        const { value } = event.target;
        if (!isNaN(value)) {
          setToDateYear(Number(value));
        }
    }
            
  return (
    <Dialog
        open={dialogState}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>
            <Typography style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>
                תפריט המוצרים
            </Typography>
        </DialogTitle>
        <DialogContent>
            <Stack direction='row'
                justifyContent="space-between"
                alignItems="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox checked={fullyPayed} onChange={(e) => setFullyPayed(e.target.checked)} name="fullyPayed" />
                  }
                  label="שולם"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={partPayed} onChange={(e) => setPartPayed(e.target.checked)} name="partPayed" />
                  }
                  label="שולם חלקית"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={notPayed} onChange={(e) => setNotPayed(e.target.checked)} name="notPayed" />
                  }
                  label="לא שולם"
                />
            </Stack>
            <Typography sx={{fontSize: 14, fontWeight: 'bold'}} style={{direction: 'rtl', unicodeBidi: 'plaintext'}}>מתאריך</Typography>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}>
                <TextField id="outlined-basic" label="יום" color="info" variant="outlined" value={fromDateDay} onChange={handleFromDateDayChange} />
                <TextField id="outlined-basic" label="חודש" color="info" variant="outlined" value={fromDateMonth} onChange={handleFromDateMonthChange} />
                <TextField id="outlined-basic" label="שנה" color="info" variant="outlined" value={fromDateYear} onChange={handleFromDateYearChange} />
            </Stack>
            <Typography sx={{fontSize: 14, fontWeight: 'bold'}} style={{direction: 'rtl', unicodeBidi: 'plaintext'}}>עד תאריך</Typography>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}>
                <TextField id="outlined-basic" label="יום" color="info" variant="outlined" value={toDateDay} onChange={handleToDateDayChange} />
                <TextField id="outlined-basic" label="חודש" color="info" variant="outlined" value={toDateMonth} onChange={handleToDateMonthChange} />
                <TextField id="outlined-basic" label="שנה" color="info" variant="outlined" value={toDateYear} onChange={handleToDateYearChange} />
            </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialogState(false)}>ביטול</Button>
          <Button onClick={ () => {
            yesFunction({ fromDateDay, fromDateMonth, fromDateYear, toDateDay,
                toDateMonth, toDateYear, fullyPayed, partPayed, notPayed})
            }}>פתיחת תפריט</Button>
        </DialogActions>
      </Dialog>
  )
}

export default MenuDialog