import { Box, Button, CircularProgress, FormControl, FormHelperText, TextField } from '@pankod/refine-mui'
import { useForm } from "@pankod/refine-react-hook-form";
import { useNotification } from '@pankod/refine-core';
import React, { useContext, } from "react";
import { ColorModeContext } from "contexts";

const CreateCustomer = () => {
  const { open } = useNotification();
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';

  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
        if (data?.response) {
          let responseMessage = data?.response?.data?.message
          open?.({
            type: "error",
            message: responseMessage,
            undoableTimeout: 6,
            description: 'טעות בנתונים',
          });
        }
      },
    },
  });

  return (
    <Box 
      borderRadius="10px"
      padding="15px">
      <form onSubmit={handleSubmit(onFinish)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>
        <FormControl variant="standard" sx={{ textAlign: 'right', direction: 'rtl' }}>
          <FormHelperText sx={{textAlign: 'right', fontWeight: "bold", fontSize: 21, color: textColor }}>שם לקוח</FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { color: textColor, fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
            {...register("name", { required: true })} 
            sx={{fontSize:20, direction: 'rtl',}}
          />
        </FormControl><br/><br/>
        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right', fontWeight: "bold", fontSize: 21, color: textColor }}>מספר טל</FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { color: textColor, fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
            {...register("phoneNumber", { required: false })} 
            sx={{fontSize:20, color: textColor, direction: 'rtl'}} 
          />
        </FormControl><br/><br/>
        <FormControl variant="standard">
          <FormHelperText sx={{textAlign: 'right', fontWeight: "bold", fontSize: 21, color: textColor }}>ח.פ</FormHelperText>
          <TextField           
            fullWidth
            id="outlined-basic"
            color="info"
            variant="outlined"
            inputProps={{ style: { color: textColor, fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
            {...register("customerNumber", { required: false })} 
            sx={{fontSize:20, color: textColor, direction: 'rtl'}} 
          />
        </FormControl><br/><br/>
        <Button type="submit" variant="contained" color="success" disabled={formLoading}>הוספה</Button>
            {formLoading && <CircularProgress/>}
      </form>
    </Box>
  )
}

export default CreateCustomer