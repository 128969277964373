import { Box, Button, FormControl, FormHelperText, TextareaAutosize, TextField, Typography } from '@pankod/refine-mui'
import { useForm } from "@pankod/refine-react-hook-form";

const CreateEvent = () => {
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm();

  return (
    <Box borderRadius="10px"
        padding="15px">
      <form onSubmit={handleSubmit(onFinish)} style={{ 
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            }}>
        <FormHelperText>
            <Typography sx={{fontWeight: "bold", fontSize: 21, textAlign: 'right'}}>:כותרת</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           required
           id="outlined-basic"
           color="info"
           variant="outlined"
          {...register("title", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText>
          <Typography sx={{fontWeight: "bold", fontSize: 21, textAlign: 'right'}}>:תאריך התחלה</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           required
           type='datetime-local'
           id="outlined-basic"
           color="info"
           variant="outlined"
          {...register("start", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText>
          <Typography sx={{fontWeight: "bold", fontSize: 21, textAlign: 'right'}}>:תאריך סיום</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           required
           type='datetime-local'
           id="outlined-basic"
           color="info"
           variant="outlined"
          {...register("end", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText>
          <Typography sx={{fontWeight: "bold", fontSize: 21, textAlign: 'right'}}>:תיאור</Typography>
        </FormHelperText>
        <FormControl fullWidth>
          <TextareaAutosize
            style={{
              width: "100%",
              background: "transparent",
              fontSize: "16px",
              borderColor: "rgba(0,0,0,0.23)",
              borderRadius: 6,
              padding: 10,
              color: "#919191",
            }}
            minRows={5}
            color="primary"
            required
            id="outlined-basic"
            {...register("description", { required: true })}
          />
        </FormControl><br/>
        <Button type="submit" variant="contained" color="success" disabled={formLoading}>הוספה</Button>
            {formLoading && <p>...טוען</p>}
      </form>
      
    </Box>
  )
}

export default CreateEvent
