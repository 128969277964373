import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@pankod/refine-mui'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import React from 'react'
import { useTable } from '@pankod/refine-core';
import Autocomplete from '@mui/material/Autocomplete';

export default function AddNewProductDialog({dialogState, yesFunction}) {
  const { tableQueryResult: productsTableQueryResult }
    = useTable({ resource: "Products" })
  const products = productsTableQueryResult.data?.data ?? [];

  const [productName, setProductName] = React.useState('');
  const [productCode, setProductCode] = React.useState('');
  const [quantity, setQuantity] = React.useState(0);
  const [firstPrice, setFirstPrice] = React.useState(0);
  const [deduction, setDeduction] = React.useState(0);
  const [lastPrice, setLastPrice] = React.useState(0);
  const [lastPriceWithTax, setLastPriceWithTax] = React.useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleProductNameChange = (event, productName) => {
    setProductName(productName);
  };

  const handleProductCodeChange = (event, productCode) => {
    setProductCode(productCode);
  };

  const handleQuantityChange = (quantity) => {
    setQuantity(quantity.target.value);
  };

  const handleFirstPriceChange = (firstPrice) => {
    setFirstPrice(firstPrice.target.value);
    setLastPrice(firstPrice.target.value * (1 - (deduction/100)))
    setLastPriceWithTax((firstPrice.target.value * (1 - (deduction/100))) * 1.17);

  };

  const handleDeductionChange = (deduction) => {
    setDeduction(deduction.target.value);
    setLastPrice(firstPrice * (1 - (deduction.target.value/100)))
    setLastPriceWithTax((firstPrice * (1 - (deduction.target.value/100))) * 1.17);

  };

  const handleLastPriceChange = (lastPrice) => {
    const newLastPrice = parseFloat(lastPrice.target.value);
    setLastPrice(lastPrice.target.value);
    setLastPriceWithTax(newLastPrice * 1.17);
    if (newLastPrice > firstPrice) {
      setFirstPrice(newLastPrice);
      setDeduction(0);
    } else {
      const diff = firstPrice - newLastPrice;
      if (firstPrice !== 0) {
        setDeduction((diff / firstPrice) * 100);
      } else {
        setFirstPrice(newLastPrice);
        setDeduction(0);
      }
    }
  };

  
  const handleLastPriceWithTaxChange = (lastPriceWithTax) => {
    const newLastPriceWithTax = parseFloat(lastPriceWithTax.target.value);
    setLastPriceWithTax(newLastPriceWithTax);
    const newLastPrice = newLastPriceWithTax / 1.17;
    setLastPrice(newLastPrice);
    if (newLastPrice > firstPrice) {
      setFirstPrice(newLastPrice);
      setDeduction(0);
    } else {
      const diff = firstPrice - newLastPrice;
      if (firstPrice !== 0) {
        setDeduction((diff / firstPrice) * 100);
      } else {
        setFirstPrice(newLastPrice);
        setDeduction(0);
      }
    }
  };
  
  
  
  return (
    <Dialog
        open={dialogState}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          הוספת מוצר לקניה
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={5}>
            <Autocomplete
              required
              freeSolo
              disablePortal
              id="combo-box-demo"
              options={products}
              getOptionLabel={(option) => option.name}
              value={{ name: productName }}
              onChange={(event, newProduct) => {
                if (newProduct) {
                  setProductName(newProduct.name);
                  if (productCode === '') {
                    setProductCode(newProduct.productCode);
                  }
                }
              }}
              onInputChange={handleProductNameChange} 
              sx={{ width: '100%', direction: 'rtl' }}
              renderInput={(params) => <TextField {...params} label="מוצר"  style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}/>}
            />

            <Autocomplete
              required
              freeSolo
              disablePortal
              id="combo-box-demo"
              options={products}
              getOptionLabel={(option) => option.productCode}
              value={{ productCode: productCode }}
              onChange={(event, newProduct) => {
                if(newProduct) {
                  setProductCode(newProduct.productCode);
                  if (productName === '') {
                    setProductName(newProduct.name);
                  }
                }
              }}
              onInputChange={handleProductCodeChange} 
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="מספר פריט"  style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}/>}
            />
          
            <TextField
              label="כמות"
              name="quantity"
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              inputProps={{ min: 0 }}
            />
            <TextField
              label="מחיר"
              name="firstPrice"
              type="number"
              value={firstPrice}
              onChange={handleFirstPriceChange}
              inputProps={{ min: 0, step: 0.0000000000000000000001 }}

            />
            <TextField
              label="הנחה"
              name="deduction"
              type="number"
              value={deduction}
              onChange={handleDeductionChange}
              inputProps={{ min: 0, max: 100, step: 0.0000000000000000000001 }}
            />
            <TextField
              label="מחיר אחרי הנחה"
              name="lastPrice"
              type="number"
              value={lastPrice}
              onChange={handleLastPriceChange}
              inputProps={{ min: 0, step: 0.0000000000000000000001 }}
            />
            <TextField
              label="מחיר כולל מע'ם"
              name="lastPriceWithTax"
              type="number"
              value={lastPriceWithTax}
              onChange={handleLastPriceWithTaxChange}
              inputProps={{ min: 0, step: 0.0000000000000000000001 }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ direction: 'rtl' }}>
          <Button sx={{ backgroundColor: 'red', color: 'white', borderRadius: '30px', ml: 1 }} onClick={() => dialogState(false)}>ביטול</Button>
          <Button sx={{ backgroundColor: 'green', color: 'white', borderRadius: '30px', mr: 1 }} autoFocus onClick={() => {
              dialogState(false);
              yesFunction({ productName ,productCode, quantity, firstPrice, deduction, lastPrice });
          }}>להוסיף</Button>
        </DialogActions>
    </Dialog>
  )  
}