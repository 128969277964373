import React from "react";

import { Refine, AuthProvider } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
} from "@pankod/refine-mui";

import { 
  Build,
  Person,
  ShoppingCart,
  CalendarMonth,
  Apartment,
  Inventory,
  Payment,
} from '@mui/icons-material';

import dataProvider from "@pankod/refine-simple-rest";
import routerProvider, { Navigate } from "@pankod/refine-react-router-v6";
import axios, { AxiosRequestConfig } from "axios";
import { ColorModeContextProvider } from "contexts";
import { Title, Sider, Layout, Header } from "components/layout";
//Pages
import { Login } from 'pages/login';
import Customers from 'pages/customers';
import Events from 'pages/events';
import OrdersHistory from 'pages/ordersHistory';
import Products from 'pages/products';
import CreateCustomer from'pages/CreateCustomer';
import CreateProduct from 'pages/CreateProduct';
import CreateOrder from 'pages/CreateOrder';
import CustomerDetails from 'pages/CustomerDetails';
import ProductDetails from 'pages/ProductDetails';
import EditProduct from 'pages/EditProduct';
import EditCustomer from 'pages/EditCustomer';
import CreateEvent from 'pages/CreateEvent';
import OrderDetails from 'pages/OrderDetails';
import EditOrder from 'pages/EditOrder';
import Suppliers from 'pages/suppliers';
import SupplierDetails from 'pages/SupplierDetails';
import CreateSupplier from "pages/CreateSupplier";
import EditSupplier from "pages/EditSupplier";
import Supplies from 'pages/supplies';
import CreateSupply from "pages/CreateSupply";
import EditSupply from "pages/EditSupply";
import SupplyDetails from "pages/SupplyDetails";
import Bids from "pages/Bids/Bids"
import CreateBid from 'pages/Bids/CreateBid'
import EditBid from 'pages/Bids/EditBid'
import EditCheck from "pages/EditCheck"

import { parseJwt } from "utils/parse-jwt";
import { CredentialResponse } from "interfaces/google";
import Checks from "pages/Checks";
import CreateCheck from "pages/CreateCheck";

import { url } from './assets/url' 


const axiosInstance = axios.create({baseURL: `${url}`, withCredentials: true});
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return request;
});

function App() {
  const authProvider: AuthProvider = {
    login: async ({ credential }: CredentialResponse) => {
      const profileObj = credential ? parseJwt(credential) : null;

      if (profileObj) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...profileObj,
            avatar: profileObj.picture,
          }),
        );

        localStorage.setItem("token", `${credential}`);

        return {
          success: true,
          redirectTo: "/",
        };
      }

      return {
        success: false,
      };
  },
    logout: async () => {
      const token = localStorage.getItem("token");

      const response = await fetch(`${url}/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });
      if(response.status === 200) {
        if (token && typeof window !== "undefined") {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          axios.defaults.headers.common = {};
          window.google?.accounts.id.revoke(token, () => {
            return Promise.resolve();
          });
        }
      }

      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      const token = localStorage.getItem("token");

      if (token) {
        return Promise.resolve();
      }
      return Promise.reject();
    },

    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) {
        return Promise.resolve(JSON.parse(user));
      }
    },
  };

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" }, body: { overflowX: 'hidden' }, '*': { boxSizing: 'border-box' } }} />
      <RefineSnackbarProvider>
        <Refine
          dataProvider={dataProvider(`${url}`, axiosInstance)}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={ <Navigate to='/' /> }
          resources={[            
            {
              name: "Products",
              list: Products,
              show: ProductDetails,
              create: CreateProduct,
              edit: EditProduct,
              icon: <Build/>,
              options: { label: "מוצרים" }
            },
            {
              name: "Customers",
              list: Customers,
              show: CustomerDetails,
              create: CreateCustomer,
              edit: EditCustomer,
              icon: <Person/>,
              options: { label: "לקוחות" }
            },
            {
              name: "Orders_History",
              list: OrdersHistory,
              create: CreateOrder,
              show: OrderDetails,
              edit: EditOrder,
              icon: <ShoppingCart/>,
              options: { label: "הזמנות_לקוח" }
            },
            {
              name: "Suppliers",
              list: Suppliers,
              create: CreateSupplier,
              show: SupplierDetails,
              edit: EditSupplier,
              icon: <Apartment/>,
              options: { label: "ספקים" }
            },
            {
              name: "Supplies",
              list: Supplies,
              create: CreateSupply,
              show: SupplyDetails,
              edit: EditSupply,
              icon: <Inventory/>,
              options: { label: "הזמנות_ספק" }
            },
            {
              name: "Events",
              list: Events,
              create: CreateEvent,
              icon: <CalendarMonth/>,
              options: { label: "אירועים" }
            },
            { 
              name: "Bids", 
              list: Bids, 
              create: CreateBid,
              edit: EditBid,
              options: {hide: true}

            },
            {
              name: "Checks",
              list: Checks,
              create: CreateCheck,
              edit: EditCheck,
              icon: <Payment/>,
              options: { label: "צ'יקים" }
            },
          ]}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          authProvider={authProvider}
          LoginPage={Login}
        />
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
