import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { useNavigate } from '@pankod/refine-react-router-v6'
import { Button, Typography,TextField, InputAdornment } from '@pankod/refine-mui';
import { Box, Stack } from '@mui/system';
import { Add } from '@mui/icons-material';
import { useTable, useDelete, useLogout } from '@pankod/refine-core'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { useState } from "react";
import EventDialog from 'components/eventDialog';
import { Search } from '@mui/icons-material'
import { CircularProgress } from '@pankod/refine-mui'


const Events = () => {
  const navigate = useNavigate();
  const { mutate } = useDelete();
  const [search, setSearch] = useState('');
  const { mutate: mutateLogout } = useLogout();
  const {
    tableQueryResult: {data, isLoading, isError},
  } = useTable();

  const handleDeleteProduct = (id) => {
    mutate(
      {
        resource: "Events",
        id,
      },
      {
        onSuccess: () => {
            navigate("/Events");
        },
      },
    );
  };

  const allEvents = data?.data ?? [];
  console.log(search)
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true)
  };

  if(isLoading) return <center><CircularProgress disableShrink /></center>
  
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  return (
    <>
      <Box>
        <Stack direction='row-reverse'
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={12}>
              <Typography fontSize='25px'
                        fontWeight='bold'>
                אירועים
              </Typography>
              <Button variant="contained" onClick={() => (navigate('/Events/create'))}
               startIcon={<Add/>} sx={{boxShadow: '2', borderRadius:'30px'}}>הוספת אירוע</Button>
        </Stack><br/>
        <TextField
            fullWidth
            id="input-with-icon-textfield"
            label="חיפוש"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            onChange={(e) => {setSearch(e.target.value)}}
        /><br/><br/>
        <FullCalendar
            locale={'HEB'}
            plugins={[ interactionPlugin, dayGridPlugin ]}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            events={allEvents}
            timeZone={'UTC'}
            dateClick={(e) => console.log(e)}     
            eventClick={handleEventClick}    
          />
        {
          openDialog && setSelectedEvent &&
          <EventDialog time={selectedEvent.event._instance.range} eventDef={selectedEvent.event._def}
          deleteFunction={handleDeleteProduct} modalStatus={setOpenDialog} />
        }
      </Box>
    </>
  )
  
}

export default Events;