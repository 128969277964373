import { LockOutlined } from '@mui/icons-material';
import { useNotification } from '@pankod/refine-core';
import { useLogin } from '@pankod/refine-core';
import { Avatar, Box, Dialog, Grid, Paper, ThemeProvider, Typography, createTheme, useMediaQuery } from '@pankod/refine-mui'
import axios from 'axios';
import { CredentialResponse } from 'interfaces/google';
import { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { url } from '../assets/url'
import OtpInput from 'react-otp-input';
import { useTheme } from '@mui/material/styles';

export const Login = () => {
  const themeScreen = useTheme();

  const fullScreen = useMediaQuery(themeScreen.breakpoints.down('md'));

  const { open: openNotifcation } = useNotification();

  const google_id = process.env.REACT_APP_GOOGLE_CLIENT_ID ? process.env.REACT_APP_GOOGLE_CLIENT_ID : ''
  const { mutate: login } = useLogin<CredentialResponse>();

  const [open, setOpen] = useState(false)
  const [pin, setPin] = useState('');
  const [loginCredential, setLoginCredential] = useState<CredentialResponse>();

  const handlePinOnChange = async (e:any) => {
    try {
      setPin(e);
      if (e.length === 5) {
        const response = await axios.post(`${url}/auth/verify-pin`, { pin: e }, { withCredentials: true });
        if (response.status === 200 && loginCredential) { login(loginCredential) }
        else { setPin('') }
      }
    } catch (error:any) {
      let serverMessage = 'טעות בקוד'
      if(error?.response?.status === 429) { serverMessage = 'אתה חסום תנסה אחרי 5דק'}
      openNotifcation?.({
        type: "error",
        message: '',
        undoableTimeout: 3,
        description: serverMessage,
      });
      setPin('')
    }
  }

  const GoogleButton = (): JSX.Element => {
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return;
      }

      try {
        window.google.accounts.id.initialize({
          ux_mode: "popup",
          client_id: google_id,
          callback: async (res: CredentialResponse) => {
            if (res.credential) {
              const id_token = res.credential;
              // Send the token to the backend for verification
              const response = await axios.post(`${url}/auth/google`, { token: id_token }, { withCredentials: true });
              if(response.status === 200) {
                setLoginCredential(res)
                setOpen(true)
              }
              else{
                console.log("you are not authorized")
                toast("you are not authorized");
              }
            }
          },
        });
        window.google.accounts.id.renderButton(divRef.current, {
          theme: "filled_blue",
          size: "medium",
          type: "standard",
        });
      } catch (error) {
        console.log(error);
      }
    }, []); // you can also add your client id as dependency here

    return <div ref={divRef} />;
  };
  const theme = createTheme();


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid 
        item
        xs={false}
        md={7}
        sx={{
          backgroundImage: 'url(https://sloanreview.mit.edu/wp-content/uploads/1982/07/Merchant-TheControlFunctionofManagement-1290x860-1.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflowX: 'hidden'
        }}/>
          <Grid item xs={12} md={5} component={Paper} elevation={6}>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlined/>
                </Avatar>
                <Typography component="h1" variant="h5">כניסה</Typography>
                <Box sx={{ mt: 1, margin: 1.5 }}>
                  <GoogleButton />
                </Box>
            </Box>
          </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
        <Box p={3}>
          <Typography variant="h6" align="center" gutterBottom>
            PIN
          </Typography>
          <Box display="flex" justifyContent="center">
           <OtpInput
              value={pin}
              onChange={handlePinOnChange}
              numInputs={5}
              renderSeparator={<span>-</span>}
              inputType='password'
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: '50px',
                height: '50px',
                fontSize: '1.5rem',
                margin: '0 5px',
              }}
           />
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  )
}