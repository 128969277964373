import { useNotification } from '@pankod/refine-core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery } from '@pankod/refine-mui';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import axios from 'axios';

interface AddPaymentModalProps {
    title: any;
    dialogState: any;
    yesFunction: any;
    url: string;
}

function AddPaymentModal({ title, dialogState, yesFunction, url }: AddPaymentModalProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { open } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState('');
  const [bank, setBank] = useState('');
  const [branch, setBranch] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [account, setAccount] = useState('');
  const [price, setPrice] = useState(0);
  const [person, setPerson] = useState('');

  const [day, setDay] = useState(new Date().getDate());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const [ifUpdateDept, setIfUpdateDept] = useState(false);

  const handleOnSubmit = async () => {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const res = await axios.post(`${url}`, {
          bank, branch, account, price, person,
          date: { day, month, year },
          number: checkNumber,
          ifUpdateDept,
          paymentType: paymentMethod
        },
        { withCredentials: true });
        if (res.status === 200) {
          yesFunction();
          dialogState(false)
        } else {
          open?.({
            type: "error",
            message: 'התשלום לא התעדכן, נסה מאוחר יותר',
            undoableTimeout: 6,
            description: 'טעות בנתונים',
          });
        } 
      } catch (error:any) {
          let errMsg = error.response?.data.message || 'התשלום לא התעדכן, נסה מאוחר יותר'
          open?.({
            type: "error",
            message: errMsg,
            undoableTimeout: 6,
            description: 'טעות בנתונים',
          });
      } finally {
          setIsLoading(false);
      }
  }
  } 
  
   return (
     <Box sx={{ direction: 'rtl' }}>
       <Dialog
         open={dialogState}
         fullScreen={fullScreen}
         fullWidth={true}
         maxWidth={'md'}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
       >
         <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', }}>{title}</DialogTitle>
         <DialogContent dir='rtl'>
           <FormControl fullWidth sx={{ direction: 'rtl' }}>
             <InputLabel id="demo-simple-select-label" sx={{ textAlign: 'right', right: 50, transformOrigin: 'top right' }}>דרך התשלום</InputLabel>
             <Select
               labelId="demo-simple-select-label"
               id="demo-simple-select"
               value={paymentMethod}
               label="Payment Method"
               onChange={(e: any) => setPaymentMethod(e.target.value)}
             >
               <MenuItem sx={{ textAlign: 'right', direction: 'rtl' }} value="check">צ"יק</MenuItem>
               <MenuItem sx={{ textAlign: 'right', direction: 'rtl' }} value="bankTransfer">העברה בנקאית</MenuItem>
               <MenuItem sx={{ textAlign: 'right', direction: 'rtl' }} value="cash">מזומן</MenuItem>
               <MenuItem sx={{ textAlign: 'right', direction: 'rtl' }} value="visa">ויזה (Visa)</MenuItem>
             </Select>
           </FormControl><br/><br/>
   
           {paymentMethod === 'check' && (
               <>
             <FormControl fullWidth variant="outlined" sx={{ direction: 'rtl'}}>
               <InputLabel htmlFor="checkNumberInput" sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>מספר צ"יק</InputLabel><br />
               <Input
                 id="checkNumberInput"
                 value={checkNumber}
                 onChange={(e) => setCheckNumber(e.target.value)}
                 sx={{ fontSize: 20, color: '#000000' }}
               />
             </FormControl><br/><br/></>
           )}
   
           {paymentMethod !== 'cash' && paymentMethod !== 'visa' && (<>
             <FormControl fullWidth variant="outlined" sx={{ direction: 'rtl' }}>
               <InputLabel htmlFor="bankNameInput" sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>שם בנק</InputLabel><br />
               <Input
                 id="bankNameInput"
                 value={bank}
                 onChange={(e) => setBank(e.target.value)}
                 sx={{ fontSize: 20, color: '#000000' }}
               />
             </FormControl><br/><br/></>
           )}
   
           {paymentMethod !== 'cash' && paymentMethod !== 'visa' && (<>
             <FormControl fullWidth variant="outlined" sx={{ direction: 'rtl' }}>
               <InputLabel htmlFor="branchInput" sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>מספר סניף</InputLabel><br />
               <Input
                 id="branchInput"
                 value={branch}
                 onChange={(e) => setBranch(e.target.value)}
                 sx={{ fontSize: 20, color: '#000000' }}
               />
             </FormControl><br/><br/></>
           )}
   
           {paymentMethod !== 'cash' && paymentMethod !== 'visa' && (<>
             <FormControl fullWidth variant="outlined" sx={{ direction: 'rtl' }}>
               <InputLabel htmlFor="accountInput" sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>מספר חשבון</InputLabel><br />
               <Input
                 id="accountInput"
                 value={account}
                 onChange={(e) => setAccount(e.target.value)}
                 sx={{ fontSize: 20, color: '#000000' }}
               />
             </FormControl><br/><br/></>
           )}
   
           <FormControl fullWidth variant="outlined" sx={{ direction: 'rtl' }}>
             <InputLabel htmlFor="priceInput" sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>סכום</InputLabel><br />
             <Input
               id="priceInput"
               type="number"
               inputProps={{
                min: 0,
                step: 0.0000000000000000000000001,
                pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
              }}
               value={price}
               onChange={(e) => {
                if (!isNaN(Number(e.target.value))) { setPrice(Number(e.target.value))}
               }}
               sx={{ fontSize: 20, color: '#000000' }}
             />
           </FormControl><br/><br/>
   
           <FormControl fullWidth variant='outlined' sx={{ direction: 'rtl' }}>
             <InputLabel htmlFor="personInput" sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>טל + שם איש קשר</InputLabel><br />
             <Input
               id="personInput"
               value={person}
               onChange={(e) => setPerson(e.target.value)}
               sx={{ fontSize: 20, color: '#000000' }}
             />
           </FormControl><br/><br/><br/>
   
           <Stack direction='row-reverse'
             justifyContent="center"
             alignItems="flex-start"
             spacing={2}>
             <TextField
               id="dayInput"
               label="יום"
               color="info"
               variant="outlined"
               type="number"
               value={day}
               onChange={(e) => setDay(Number(e.target.value))}
             />
             <TextField
               id="monthInput"
               label="חודש"
               color="info"
               variant="outlined"
               type="number"
               value={month}
               onChange={(e) => setMonth(Number(e.target.value))}
             />
             <TextField
               id="yearInput"
               label="שנה"
               color="info"
               variant="outlined"
               type="number"
               value={year}
               onChange={(e) => setYear(Number(e.target.value))}
             />
           </Stack><br />

           <FormControl fullWidth>
            <InputLabel sx={{ fontSize: 22, fontWeight: "bold", textAlign: 'right', right: 20, transformOrigin: 'top right' }}>האם לעדכן החוב ?</InputLabel><br />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={ifUpdateDept ? 0 : 1}
              onChange={(e:any) => {
                setIfUpdateDept(e.target.value === 0);
              }}
            >
              <MenuItem value={0}>כן</MenuItem>
              <MenuItem value={1}>לא</MenuItem>
            </Select>
          </FormControl>

   
         </DialogContent>
         <DialogActions>
           <Button onClick={() => dialogState(false)}>ביטול</Button>
           {!isLoading ? <Button autoFocus onClick={handleOnSubmit}>להוסיף</Button> : <CircularProgress/>}
           
         </DialogActions>
       </Dialog>
     </Box>
   )
  }
  
  export default AddPaymentModal;
  