import React, { useContext } from 'react'
import { useForm } from "@pankod/refine-react-hook-form";
import { Button, FormControl, FormHelperText, Stack, TextField, TextareaAutosize, Typography } from '@pankod/refine-mui';
import { ColorModeContext } from "contexts";

const EditOrder = () => {
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';

  const {
      refineCore: { onFinish, formLoading, queryResult, },
      register,
      handleSubmit,
  } = useForm();

  const getTotalPrice = (products : any) => {
    return products.reduce((totalPrice : any, product : any) => {
      const price = product.product.sellPrice;
      const chosenQuantity = product.chosenQuantity;
      const discount = (1 - (product.dedication/100));
      return totalPrice + (price * chosenQuantity * discount);
    }, 0);
  };

  const order = queryResult?.data?.data
  const totalPrice = Number(getTotalPrice(order?.products)) * (1-(order?.dedication/100)) * 1.17;

  return (
    <div>
      <form onSubmit={handleSubmit(onFinish)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>
        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            תיאור
        </FormHelperText>
        <FormControl fullWidth>
        <TextareaAutosize
          style={{
            width: "100%",
            background: "transparent",
            fontSize: "17px",
            borderRadius: 6,
            padding: 10,
            direction: 'rtl', 
            color: textColor, 
          }}
          minRows={5}
          color="primary"
          id="outlined-basic"
          {...register("description")}
        />
        </FormControl><br/>
        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>מצב תשלום</Typography>
        </FormHelperText>
        <FormControl fullWidth>
          <Stack direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography sx={{fontSize: { xs: '20px', sm: '22px', md: '25px' }, 
              fontWeight: 'bold', mt: 1, direction: 'rtl', whiteSpace: 'nowrap',}}>מ- {Number(totalPrice).toFixed(2)}₪</Typography>
            <TextField
              fullWidth
              required
              type="number"
              inputProps={{ 
                min: 0,
                step: 0.0000000000000000000000001,
                pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
                style: { color: textColor, fontSize: '18px' }
              }}
              id="outlined-basic"
              color="info"
              variant="outlined"
              {...register("Payed", { required: true })}
            />
          </Stack>
        </FormControl><br/>

        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>זיכוי</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          required
          type="number"
          inputProps={{ 
            min: 0,
            step: 0.0000000000000000000000001,
            pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
            style: { color: textColor, fontSize: '18px' }
          }}
          id="outlined-basic"
          color="info"
          variant="outlined"
          {...register("credit", { required: true })}
        />
        </FormControl><br/>

        <Button type="submit" variant="contained" color="success" disabled={formLoading}>עריכה</Button>

          {formLoading && <p>...טוען</p>}
      </form>
    </div>
  )
}

export default EditOrder