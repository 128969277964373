import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import { DialogContent, TextField, Typography } from '@pankod/refine-mui'
import { useState } from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmationDialog({ dialogState, yesFunction, message }) {
  const [confirmation, setConfirmation] = useState("");
  return (
    <Dialog
        open={dialogState}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Typography fontSize='20px' style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>{message}</Typography>
          <Typography fontSize='20px' style={{ direction: 'rtl', unicodeBidi: 'plaintext' }}>נא לכתוב "אישור":</Typography>
          <TextField id="outlined-basic" label="" variant="outlined" onChange={(e) => {setConfirmation(e.target.value)}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => dialogState(false)}>ביטול</Button>
          <Button onClick={ () => {
            if (confirmation === "אישור") {
              yesFunction()
              dialogState(false)
            }}} sx={{boxShadow: 3, borderRadius: 10, bgcolor: '#FF0000', color: '#FFFFFF'}}>אישור</Button>
        </DialogActions>
      </Dialog>
  )
}

export default ConfirmationDialog
