import { useForm } from "@pankod/refine-react-hook-form";
import { Box, Button, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField } from '@pankod/refine-mui'
import { useNotification } from '@pankod/refine-core';
import { ColorModeContext } from "contexts";
import { useContext } from "react";

const EditCustomer = () => {
  const { open } = useNotification();
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';

  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
        if (data?.response) {
          let responseMessage = data?.response?.data?.message
          open?.({
            type: "error",
            message: responseMessage,
            undoableTimeout: 6,
            description: 'טעות בנתונים',
          });
        }
      },
    },
  });

  return (
    <Box
    borderRadius="10px"
    padding="15px">
      <form onSubmit={handleSubmit(onFinish)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>
        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            שם לקוח
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           required
           id="outlined-basic"
           color="info"
           variant="outlined"
           inputProps={{ style: { color: textColor, fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
          {...register("name", { required: true })}
        />
        </FormControl><br/>
        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            מספר טל
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           id="outlined-basic"
           color="info"
           variant="outlined"
           inputProps={{ style: { color: textColor, fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
          {...register("phoneNumber", { required: true })}
        />
        </FormControl><br/>
        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
          ח.פ
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           id="outlined-basic"
           color="info"
           variant="outlined"
           inputProps={{ style: { color: textColor, fontSize: '17px', direction: 'rtl', fontWeight: 'bold' } }}
          {...register("customerNumber", { required: false })}
        />
        </FormControl><br/>
        <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount" sx={{fontSize: '21px', fontWeight: 'bold', color: textColor}}>חוב</InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              min: 0,
              step: 0.01,
              pattern: "^\\d+(\\.\\d{0,2})?$",
              style: { color: textColor, fontSize: '18px' }
            }}
            label="Amount"
            {...register("dept", { required: true })}
          />
        </FormControl><br/>

        <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount" sx={{fontSize: '21px', fontWeight: 'bold', color: textColor}}>זיכוי</InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              min: 0,
              step: 0.01,
              pattern: "^\\d+(\\.\\d{0,2})?$",
              style: { color: textColor, fontSize: '18px' }
            }}
            label="Amount"
            {...register("credit", { required: true })}
          />
        </FormControl><br/>
        
        <Button type="submit" variant="contained" color="success" disabled={formLoading}>עריכה</Button>
            {formLoading && <p>...טוען</p>}
      </form>
    </Box>
  )
}

export default EditCustomer
