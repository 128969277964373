import { useTable, useNotification } from '@pankod/refine-core';
import { Autocomplete, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, Stack,
Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, TextField, Tooltip, Typography} from '@pankod/refine-mui'
import { FieldValues, useForm } from "@pankod/refine-react-hook-form";
import AddNewProductDialog from "../components/addNewProductDialog"
import { useState } from 'react';
import { Add, Delete, Edit } from '@mui/icons-material';
import React, { useContext, } from "react";
import { ColorModeContext } from "contexts";
import EditProductModal from 'components/EditProductModal';

type Product = { productName: string, productCode: string, quantity: number, firstPrice: number, deduction: number, lastPrice: number };

const CreateSupply = () => {
  const { open } = useNotification();
  const [openDialog, setOpenDialog] = useState(false);
  const [chosenSupplier, setChosenSupplier] = React.useState({});
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';
  const [openEditProductDialog, setOpenEditProductDialog] = useState<number>(-1);


  const { tableQueryResult: suppliersTableQueryResult  }
    = useTable({ resource: "Suppliers" })
  let suppliers = suppliersTableQueryResult.data?.data ?? [];

  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
          if (data?.response) {
            let responseMessage = data?.response?.data?.message
            open?.({
              type: "error",
              message: responseMessage,
              undoableTimeout: 6,
              description: 'טעות בנתונים',
            });
          }
        },
    },
  });

  const [chosenProducts, setChosenProducts] = useState<{ product: Product; }[]>([]);

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({
        ...data,
        products: chosenProducts,
        supplier: chosenSupplier,
    });
  };

  const handleClickAddProduct = (product: Product) => {
    const { deduction, } = product;
    if (Number(deduction) > 100 || Number(deduction) < 0) { return }
    setChosenProducts([...chosenProducts, { product }]);
  };

  const handleEditProduct = (editedProduct:any) => {
    const updatedProducts = chosenProducts.map((p, i) => i === openEditProductDialog ? { product: editedProduct } : p);
    setChosenProducts(updatedProducts);
    setOpenEditProductDialog(-1);
  };

  return (
    <form onSubmit={handleSubmit(onFinishHandler)} style={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
     }}>

      <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
        :תיאור
      </FormHelperText>
      <FormControl fullWidth>
        <TextareaAutosize
          style={{
            width: "100%",
            background: "transparent",
            fontSize: "18px",
            borderColor: "rgba(0,0,0,0.23)",
            borderRadius: 6,
            padding: 10,
            color: textColor,
            direction: 'rtl', 
            resize: 'none',
            textAlign: 'right',
          }}
          minRows={4}
          color="primary"
          id="outlined-basic"
          {...register("description")}
        />
      </FormControl><br/>

      <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>מספר חשבונית</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           required
           sx={{ direction: 'rtl', textAlign: 'right', }}
           id="outlined-basic"
           inputProps={{ style: { color: textColor, fontSize: '18px', } }}
           color="info"
           variant="outlined"
          {...register("invoicingNum", { required: true })}
        />
      </FormControl><br/>

      <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
        :ספאק
      </FormHelperText>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        disableClearable
        options={suppliers}
        getOptionLabel={(option) => option.companyName + " " + option.companyNumber }
        onChange={((_event, supplierGetChosen) => {
          setChosenSupplier(supplierGetChosen)
        })}
        renderInput={(params) => 
        <TextField dir='rtl' {...params} label="" />}
      /><br/>
        <Stack spacing={2} direction='row-reverse' justifyContent="space-between">
          <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor}}>:מוצרים</Typography>
          <Stack spacing={2} direction='row' justifyContent='flex-start'>
            <Button onClick={() => setOpenDialog(true)}
              sx={{borderRadius: 10, maxWidth: 200 }} variant="contained">הוספת מוצר<Add/></Button>
            <FormControlLabel control={<Checkbox />} {...register("ifUpdateProductsQuantity")} label="עדכן כמות המוצרים" />
          </Stack>
        </Stack>
      <br/>
      <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>פעולות</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>כולל מע"ם</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>סה"כ בש"ח</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>מחיר נטו</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>%</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>מ. יחידה</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>כמות</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>מספר פריט</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, textAlign: 'right', color: textColor}}>שם פריט</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chosenProducts.map((p, index) => (
              <TableRow>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>
                  <Tooltip title={<Typography style={{ fontSize: '17px' }}>עריכת מוצר</Typography>} placement="top">
                    <IconButton color="primary" onClick={() => setOpenEditProductDialog(index) }>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Typography style={{ fontSize: '17px' }}>מחיקת מוצר</Typography>} placement="top">
                    <IconButton style={{ color: '#FF0000' }} onClick={() => setChosenProducts(chosenProducts.filter(product => product.product.productCode !== p.product.productCode))}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>{Number(p.product.quantity * p.product.lastPrice * 1.17).toFixed(2)}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>{Number(p.product.quantity * p.product.lastPrice).toFixed(2)}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>{Number(p.product.lastPrice).toFixed(2)}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>{p.product.deduction}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>{Number(p.product.firstPrice).toFixed(2)}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right'}}>{p.product.quantity}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right', direction: 'rtl',}} style={{ wordBreak: 'break-word', }}>{p.product.productCode}</TableCell>
                <TableCell sx={{fontSize: 19, textAlign: 'right', direction: 'rtl',}} style={{ wordBreak: 'break-word', }}>{p.product.productName}</TableCell>
                {openEditProductDialog === index  && 
                  <EditProductModal
                    dialogState={setOpenEditProductDialog} 
                    yesFunction={handleEditProduct} 
                    product={p} 
                />}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer><br/>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
        <Stack direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
          {chosenProducts.map((p:any) => (
          <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1 }} key={p.product.productCode}>
            <CardContent>
              <Stack direction='column' justifyContent="space-between" spacing={0.3}>
                <Typography fontSize={22} fontWeight='bold' sx={{textAlign: 'right', color: textColor}} style={{ direction: 'rtl', }}>
                  {p.product.productName}
                </Typography>
                <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}} style={{ direction: 'rtl', }}>מק"ט: {p.product.productCode}</Typography>
                <Stack direction='row-reverse' justifyContent="space-between">
                  <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>₪מחיר ליחיד: {p.product.firstPrice}</Typography>
                  <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>הנחה: {p.product.deduction}</Typography>
                </Stack>
                <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>מחיר נטו: {p.product.lastPrice}</Typography>
                <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>כמות: {p.product.quantity}</Typography>
                <Typography sx={{fontSize: '19px', fontWeight: 'bold', textAlign: 'left', color: textColor}}>סה"כ בש"ח: {p.product.lastPrice * p.product.quantity}</Typography>
              </Stack>
              <CardActions>
                <Button onClick={() => setChosenProducts(chosenProducts.filter(product => product.product.productCode !== p.product.productCode))}
                  sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>                   
              </CardActions>
            </CardContent>
          </Card>
          ))}
        </Stack>
        <br/>
      </Box>
      {openDialog && <AddNewProductDialog dialogState={setOpenDialog} yesFunction={handleClickAddProduct}/>}
      <TextField
        defaultValue={0}
        inputProps={{ min: -100, max: 100, step: 0.0000000000000000000000001 }}
        label="הנחה"
        variant="outlined"
        type="number"
        id="dedication"
        {...register("dedication")}
      /> <br/>
      
      <TextField
        defaultValue={0}
        inputProps={{ 
          min: 0, 
          step: 0.0000000000000000000000001,
          pattern: "^\\d+(\\.\\d{0,2})?$",
        }}
        label=":שולם"
        variant="outlined"
        type="number"
        id="Payed"
        {...register("Payed")}
      /> <br/>
      <Button type="submit" variant="contained" color="success" disabled={formLoading}>הוספה</Button>
          {formLoading && <CircularProgress/>}
     </form>
  )
}

export default CreateSupply